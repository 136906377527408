import gsap from 'gsap';
import { Power4 } from 'gsap/all';

const searchField = document.querySelector('.search');
const menu = document.querySelector('.menu');
const searchForm = document.querySelector('.search__input');
const line = document.querySelector('.line__element');
const searchLine = document.querySelector('.search-line__element');

gsap.set( searchLine, { x: '-100%' });
const searchTl = gsap.timeline({
  paused: true
}).fromTo(
  searchField,
  { y: '-110%'},
  { y: '0%',
    duration: 0.8,
    ease: Power4.easeOut,
  }
).fromTo(
  searchLine,
  { x: '-100%' },
  { x: 0, duration: 0.6, ease: Power4.easeOut },
  '-=0.3'
).fromTo(
  searchForm,
  { y: 18 },
  { y: 0,
    transformOrigin: 'bottom',
    duration: 1
  }, '-=0.8'
);


export const searchAppearance = () => {  
    searchTl.timeScale(1).play()
};

export const searchDisappearance = () => {
    searchTl.timeScale(3.5).reverse()
      };

const menuLinks = [...document.querySelectorAll('.js-menu-link')]
const menuItems = [...document.querySelectorAll('.js-menu-item')]

const desktopScreen = window.matchMedia('(min-width: 1024px)');

gsap.set(menuLinks, { y: 100 });
let tl=null
if (desktopScreen.matches) {
  tl = gsap.timeline({
    paused: true
  }).fromTo(
    menu,
    { y: '-110%' },
    {
      y: '0%',
      duration: 0.8,
      ease: Power4.easeOut,
    }
  ).fromTo(line, { x: '-100%' }, { x: 0, duration: 1, ease: Power4.easeOut },
    '-=0.2')
    .fromTo(
      menuLinks,
      { y: 100 },
      {
        y: 0,
        transformOrigin: 'bottom',
        stagger: 0.1,
        duration: 2,
        ease: 'expo.out'
      },
      '-=1.3'
    )
} else {
    tl = gsap.timeline({
    paused: true
  }).fromTo(
    menu,
    { y: '-110%' },
    {
      y: '0%',
      duration: 0.8,
      ease: Power4.easeOut,
    }
  ).fromTo(line, { x: '-100%' }, { x: 0, duration: 1, ease: Power4.easeOut },
    '-=0.3')
    .fromTo(
      menuLinks,
      { y: 100 },
      {
        y: 0,
        transformOrigin: 'bottom',
        stagger: 0.05,
        duration: 2,
        ease: 'expo.out'
      },
      '-=1.5'
    )
  }
    menuItems.forEach((item, i) =>
    gsap.fromTo(
      item,
    {y: 0},
    {
      y: i*-17,
      transformOrigin: 'bottom',
      stagger: 0.02,
      duration: 0.5
      
    },
    '-=0.7'
    )
    )
    
export const menuAppearance = () => {
  tl.timeScale(1).play()
};

export const menuDisappearance = () => {
  tl.timeScale(3.5).reverse()
};

