/* eslint-disable */
import Lenis from '@studio-freight/lenis';

import { getElementByDataType } from './utils';

export const initLenisScroll = ({ wrapperType, contentType, scollName }) => {
    const wrapper = getElementByDataType(wrapperType);
    const content = getElementByDataType(contentType, wrapper);

    const lenis = new Lenis({
        duration: 3,
        easing: t => (t === 1 ? 1 : 1 - Math.pow(2, -10 * t)),
        direction: 'vertical',
        gestureDirection: 'vertical',
        smooth: true,
        smoothTouch: false,
        mouseMultiplier: 0.5,
        inifinite: false,
        wrapper,
        content,
        // ...config,
    });

    if (scollName) {
        window[scollName] = lenis;
    }

    function raf(time) {
        lenis.raf(time);
        requestAnimationFrame(raf);
    }
    requestAnimationFrame(raf);
};

export const destroyLenisScroll = scollName => {
    window[scollName].destroy();

    setTimeout(() => {
        delete window[scollName];
    }, 100);
};
