/* eslint-disable */
import { getElementByDataType } from '../lib/utils';
import { productList } from '../mock/productList';

function disableSubmitByEnter() {
    const $searchInput = getElementByDataType('search-input');
    const $searchResult = getElementByDataType('search-result');

    const searchProductHandler = e => {
        const value = e.target.value.toLocaleLowerCase();

        const foundProducts = value.trim()
            ? productList.filter(p => p.name.toLocaleLowerCase().includes(value)).slice(0, 4)
            : [];

        renderFoundProducts(foundProducts);
    };

    const renderFoundProducts = products => {
        const renderedProducts = products
            .map(
                p => `
                <a href="/product.html?collection=${p.collection}&model=${p.model}&price=${p.price}" data-collection="${p.collection}" class="search__item">
                    <div class="search__image">
                        <picture>
                            <source type="image/webp" srcset="${p.imgView2_1x}" />
                            <source type="image/jpeg" srcset="${p.imgView2}" />
                            <img class="search__img" src="${p.imgView2_1x}" alt="${p.name}" />
                        </picture>
                    </div>
                    <div class="search__info">
                        <p>${p.name}</p>
                        <p class="grey">${p.price}</p>
                    </div>
                </a>
        `
            )
            .join(' ');

        $searchResult.innerHTML = renderedProducts;
    };

    $searchResult.addEventListener('click', () => {
        if (!$searchResult.children.length) return;
        window.searchClose();
        $searchInput.value = '';
    });

    $searchInput.addEventListener('input', searchProductHandler);
}

export default disableSubmitByEnter;
