/* eslint-disable */

export const getElementByDataType = (dataType, $parentElement) => {
    if ($parentElement) {
        return $parentElement.querySelector(`[data-type="${dataType}"]`);
    }

    return document.querySelector(`[data-type="${dataType}"]`);
};

export const storage = (key, value) => {
    if (value || isNull(value)) {
        localStorage.setItem(key, JSON.stringify(value));
        return;
    }

    return JSON.parse(localStorage.getItem(key));
};

export const isNull = value => value === null;

export const getInlineStyle = ($el, style) => window.getComputedStyle($el, null)[style];
