/* eslint-disable */

import Lenis from '@studio-freight/lenis';
import { disableScroll, enableScroll } from '../lib/handleScrollBody';

import parallax from './parallax';

const smoothScrollModal = () => {
    const wrapper = document.querySelector('.js-zoom');
    const content = wrapper.querySelector('.js-zoom-content');

    const lenis = new Lenis({
        duration: 3,
        // eslint-disable-next-line no-restricted-properties
        easing: t => (t === 1 ? 1 : 1 - Math.pow(2, -10 * t)),
        direction: 'vertical',
        gestureDirection: 'vertical',
        smooth: true,
        smoothTouch: false,
        mouseMultiplier: 0.5,
        inifinite: false,
        wrapper,
        content,
    });

    window.lenisModal = lenis;

    function raf(time) {
        lenis.raf(time);
        requestAnimationFrame(raf);
    }

    requestAnimationFrame(raf);
};

const initProduct = () => {
    smoothScrollModal();
    window.lenisModal.stop();

    $('.gallery_slides img, .single_close').on('click', function () {
        // Disable mobile zoom on desktop devices
        if (window.innerWidth > 1023) {
            return;
        }

        const $target = $(this);
        const zoomSrc = $target.data('src');

        if ($target.attr('class').match('single_close')) {
            $('.single').attr('style', 'overflow: unset');
            $('.zoom img').attr('src', '');
            enableScroll();
            window.lenisModal.stop();
            window.lenis.start();
        } else {
            $('.single').attr('style', 'overflow: hidden');
            $('.zoom img').attr('src', zoomSrc);
            disableScroll();
            window.lenisModal.start();
            window.lenis.stop();
        }

        $('.zoom, .single_close').toggleClass('active');
        $('.product_nav').toggleClass('active');
        $('.header_info, .nav_toggle').toggleClass('none');
    });

    const gallery = [...document.querySelectorAll('.swiper-slide__img--base')];
    const lastImg = document.querySelector('.swiper-slide__img');
    const galleryContainer = document.querySelector('.gallery');
    const thumbsLength = document.querySelectorAll('[data-thumb]').length;

    let initGallery = false;

    window.setGalleryHeight = function setGalleryHeight() {
        if (!galleryContainer) return;

        console.log('gallery SART');

        gallery.forEach(currentValue => {
            if (currentValue.clientHeight) {
                initGallery = true;
            } else {
                initGallery = false;
            }
        }, 0);

        if (!initGallery) {
            console.log('gallery FALSE');

            setTimeout(() => {
                window.setGalleryHeight();
            }, 500);
            return;
        }

        console.log('gallery TRUE');

        const PARALAX_PERCENTAGE = 0.75;

        const sumSlidesHeight = gallery.reduce((previousValue, currentValue) => {
            return previousValue + currentValue.clientHeight;
        }, 0);

        const calculdatedHeight =
            sumSlidesHeight / thumbsLength + lastImg.clientHeight * PARALAX_PERCENTAGE;

        if (calculdatedHeight) {
            galleryContainer.setAttribute('style', `height: ${calculdatedHeight}px;`);
            parallax();
        }
    };

    window.addEventListener('resize', setGalleryHeight);
    window.addEventListener('load', setGalleryHeight);
    setGalleryHeight();
};

export default initProduct;
