import validator from 'validator';

function validation() {
    const inputs = document.querySelectorAll('.form__input');
    const password = document.querySelector('#password');
    const newPassword = document.querySelector('#newPassword');
    const currentPassword = document.querySelector('#currentPassword');
    const textArea = document.querySelector('#message');

    const checkInput = e => {
        const inputValue = e.target.value;
        const inputId = e.target.id;
        const target = document.querySelector(`#${inputId}`);
        const initLabel = target.previousElementSibling.getAttribute('value');
        let result = '';

        const setStyles = res => {
            const prev = target.previousElementSibling;
            switch (res) {
                case 'valid':
                    target.classList.remove('form--invalid');
                    prev.classList.remove('form__label--invalid');
                    prev.textContent = initLabel;
                    break;

                default:
                    target.classList.add('form--invalid');
                    prev.classList.add('form__label--invalid');
                    prev.textContent = target.dataset.errorMessage;
                    break;
            }
        };

        const validate = check => {
            result = check ? 'valid' : 'invalid';
            setStyles(result);
        };

        switch (inputId) {
            case 'email':
                validate(validator.isEmail(inputValue));
                break;
            case 'emailSubscribe':
                validate(validator.isEmail(inputValue));
                break;
            case 'password':
                validate(validator.isStrongPassword(inputValue));
                break;
            case 'confirmPassword':
                validate(validator.equals(inputValue, password.value));
                break;
            case 'name':
                validate(
                    validator.isLength(inputValue, 2, 100) && !validator.isNumeric(inputValue)
                );
                break;
            case 'lastName':
                validate(
                    validator.isLength(inputValue, 2, 100) && !validator.isNumeric(inputValue)
                );
                break;
            case 'currentPassword':
                validate(!validator.isEmpty(inputValue));
                break;
            case 'newPassword':
                validate(
                    validator.isStrongPassword(inputValue) &&
                        !validator.equals(inputValue, currentPassword.value)
                );
                break;
            case 'confirmNewPassword':
                validate(validator.equals(inputValue, newPassword.value));
                break;
            case 'address':
                validate(!validator.isEmpty(inputValue));
                break;
            case 'company':
                validate(!validator.isEmpty(inputValue) && !validator.isNumeric(inputValue));
                break;
            case 'city':
                validate(!validator.isEmpty(inputValue) && !validator.isNumeric(inputValue));
                break;
            case 'postal':
                validate(!validator.isEmpty(inputValue));
                break;
            case 'areaCode':
                validate(!validator.isEmpty(inputValue));
                break;
            case 'phone':
                validate(validator.isNumeric(inputValue) && validator.isLength(inputValue, 7, 20));
                break;
            case 'message':
                validate(!validator.isEmpty(inputValue));
                break;
            default:
                break;
        }
    };

    inputs.forEach(input => input.addEventListener('blur', checkInput));
    if (textArea) {
        textArea.addEventListener('blur', checkInput);
    }
}

export default validation;
