const copyLink = () => {
    const linkBtn = document.getElementById('copy');
    const linkBtnText = linkBtn.querySelector('[data-type="text"]');
    const linkBtnLine = linkBtn.querySelector('[data-type="line"]');

    linkBtn.onclick = function copy() {
        const text = document.getElementById('content').value;
        navigator.clipboard
            .writeText(text)
            .then(() => {
                linkBtnText.textContent = linkBtn.dataset.copied;
                linkBtnLine.style.display = 'none';
                setTimeout(() => {
                    linkBtnText.textContent = linkBtn.dataset.share;
                    linkBtnLine.style.display = 'block';
                }, 1000);
            })
            .catch(err => {
                console.error('Error in copying text: ', err);
            });
    };
};

export default copyLink;
