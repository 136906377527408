/* eslint-disable */

export const productGalleryList = [
    // ANIMYS Y1
    {
        collection: 'animus-y1',
        title: 'ANIMYS y1',
        price: '$245.00',
        info: [
            {
                id: 1,
                model: '001',
                thumbs: {
                    '1x': 'img/products/ANIMUS-Y1-001/6.jpg',
                    '2x': 'img/products/ANIMUS-Y1-001/5.jpg',
                },
                photos: [
                    {
                        '1x': 'img/products/ANIMUS-Y1-001/2.1.jpg',
                        '2x': 'img/products/ANIMUS-Y1-001/1.1.jpg',
                        mobile: 'img/products/ANIMUS-Y1-001/3.1.jpg',
                        zoomImg: 'img/products/ANIMUS-Y1-001/4.1.jpg',
                    },
                    {
                        '1x': 'img/products/ANIMUS-Y1-001/2.2.jpg',
                        '2x': 'img/products/ANIMUS-Y1-001/1.2.jpg',
                        mobile: 'img/products/ANIMUS-Y1-001/3.2.jpg',
                        zoomImg: 'img/products/ANIMUS-Y1-001/4.2.jpg',
                    },
                    {
                        '1x': 'img/products/ANIMUS-Y1-001/2.3.jpg',
                        '2x': 'img/products/ANIMUS-Y1-001/1.3.jpg',
                        mobile: 'img/products/ANIMUS-Y1-001/3.3.jpg',
                        zoomImg: 'img/products/ANIMUS-Y1-001/4.3.jpg',
                    },
                    {
                        '1x': 'img/products/ANIMUS-Y1-001/2.4.jpg',
                        '2x': 'img/products/ANIMUS-Y1-001/1.4.jpg',
                        mobile: 'img/products/ANIMUS-Y1-001/3.4.jpg',
                        zoomImg: 'img/products/ANIMUS-Y1-001/4.4.jpg',
                    },
                ],
                text: 'METICULOUSLY CRAFTED TO TURN HEADS, THESE OVERSIZED YET GEOMETRICALLY BALANCED FRAMES WITH UNIQUELY ANGLED TEMPLES WERE CRAFTED WITH YOU IN MIND. ESCAPE FROM THE MUNDANE QUOTIDIAN ROUTINE AND RELEASE THE INNER YOU.',
                details: 'Details',
                size: '41',
                color: 'pink',
                returns: 'returns info',
            },
            {
                id: 2,
                model: '002',
                thumbs: {
                    '1x': 'img/products/ANIMUS-Y1-002/6.jpg',
                    '2x': 'img/products/ANIMUS-Y1-002/5.jpg',
                },
                photos: [
                    {
                        '1x': 'img/products/ANIMUS-Y1-002/2.1.jpg',
                        '2x': 'img/products/ANIMUS-Y1-002/1.1.jpg',
                        mobile: 'img/products/ANIMUS-Y1-002/3.1.jpg',
                        zoomImg: 'img/products/ANIMUS-Y1-002/4.1.jpg',
                    },
                    {
                        '1x': 'img/products/ANIMUS-Y1-002/2.2.jpg',
                        '2x': 'img/products/ANIMUS-Y1-002/1.2.jpg',
                        mobile: 'img/products/ANIMUS-Y1-002/3.2.jpg',
                        zoomImg: 'img/products/ANIMUS-Y1-002/4.2.jpg',
                    },
                    {
                        '1x': 'img/products/ANIMUS-Y1-002/2.3.jpg',
                        '2x': 'img/products/ANIMUS-Y1-002/1.3.jpg',
                        mobile: 'img/products/ANIMUS-Y1-002/3.3.jpg',
                        zoomImg: 'img/products/ANIMUS-Y1-002/4.3.jpg',
                    },
                    {
                        '1x': 'img/products/ANIMUS-Y1-002/2.4.jpg',
                        '2x': 'img/products/ANIMUS-Y1-002/1.4.jpg',
                        mobile: 'img/products/ANIMUS-Y1-002/3.4.jpg',
                        zoomImg: 'img/products/ANIMUS-Y1-002/4.4.jpg',
                    },
                ],
                text: 'METICULOUSLY CRAFTED TO TURN HEADS, THESE OVERSIZED YET GEOMETRICALLY BALANCED FRAMES WITH UNIQUELY ANGLED TEMPLES WERE CRAFTED WITH YOU IN MIND. ESCAPE FROM THE MUNDANE QUOTIDIAN ROUTINE AND RELEASE THE INNER YOU.',
                details: 'Details',
                size: '41',
                color: 'pink',
                returns: 'returns info',
            },
            {
                id: 3,
                model: '003',
                thumbs: {
                    '1x': 'img/products/ANIMUS-Y1-003/6.jpg',
                    '2x': 'img/products/ANIMUS-Y1-003/5.jpg',
                },
                photos: [
                    {
                        '1x': 'img/products/ANIMUS-Y1-003/2.1.jpg',
                        '2x': 'img/products/ANIMUS-Y1-003/1.1.jpg',
                        mobile: 'img/products/ANIMUS-Y1-003/3.1.jpg',
                        zoomImg: 'img/products/ANIMUS-Y1-003/4.1.jpg',
                    },
                    {
                        '1x': 'img/products/ANIMUS-Y1-003/2.2.jpg',
                        '2x': 'img/products/ANIMUS-Y1-003/1.2.jpg',
                        mobile: 'img/products/ANIMUS-Y1-003/3.2.jpg',
                        zoomImg: 'img/products/ANIMUS-Y1-003/4.2.jpg',
                    },
                    {
                        '1x': 'img/products/ANIMUS-Y1-003/2.3.jpg',
                        '2x': 'img/products/ANIMUS-Y1-003/1.3.jpg',
                        mobile: 'img/products/ANIMUS-Y1-003/3.3.jpg',
                        zoomImg: 'img/products/ANIMUS-Y1-003/4.3.jpg',
                    },
                    {
                        '1x': 'img/products/ANIMUS-Y1-003/2.4.jpg',
                        '2x': 'img/products/ANIMUS-Y1-003/1.4.jpg',
                        mobile: 'img/products/ANIMUS-Y1-003/3.4.jpg',
                        zoomImg: 'img/products/ANIMUS-Y1-003/4.4.jpg',
                    },
                ],
                text: 'METICULOUSLY CRAFTED TO TURN HEADS, THESE OVERSIZED YET GEOMETRICALLY BALANCED FRAMES WITH UNIQUELY ANGLED TEMPLES WERE CRAFTED WITH YOU IN MIND. ESCAPE FROM THE MUNDANE QUOTIDIAN ROUTINE AND RELEASE THE INNER YOU.',
                details: 'Details',
                size: '41',
                color: 'pink',
                returns: 'returns info',
            },
            {
                id: 4,
                model: '004',
                thumbs: {
                    '1x': 'img/products/ANIMUS-Y1-004/6.jpg',
                    '2x': 'img/products/ANIMUS-Y1-004/5.jpg',
                },
                photos: [
                    {
                        '1x': 'img/products/ANIMUS-Y1-004/2.1.jpg',
                        '2x': 'img/products/ANIMUS-Y1-004/1.1.jpg',
                        mobile: 'img/products/ANIMUS-Y1-004/3.1.jpg',
                        zoomImg: 'img/products/ANIMUS-Y1-004/4.1.jpg',
                    },
                    {
                        '1x': 'img/products/ANIMUS-Y1-004/2.2.jpg',
                        '2x': 'img/products/ANIMUS-Y1-004/1.2.jpg',
                        mobile: 'img/products/ANIMUS-Y1-004/3.2.jpg',
                        zoomImg: 'img/products/ANIMUS-Y1-004/4.2.jpg',
                    },
                    {
                        '1x': 'img/products/ANIMUS-Y1-004/2.3.jpg',
                        '2x': 'img/products/ANIMUS-Y1-004/1.3.jpg',
                        mobile: 'img/products/ANIMUS-Y1-004/3.3.jpg',
                        zoomImg: 'img/products/ANIMUS-Y1-004/4.3.jpg',
                    },
                    {
                        '1x': 'img/products/ANIMUS-Y1-004/2.4.jpg',
                        '2x': 'img/products/ANIMUS-Y1-004/1.4.jpg',
                        mobile: 'img/products/ANIMUS-Y1-004/3.4.jpg',
                        zoomImg: 'img/products/ANIMUS-Y1-004/4.4.jpg',
                    },
                ],
                text: 'METICULOUSLY CRAFTED TO TURN HEADS, THESE OVERSIZED YET GEOMETRICALLY BALANCED FRAMES WITH UNIQUELY ANGLED TEMPLES WERE CRAFTED WITH YOU IN MIND. ESCAPE FROM THE MUNDANE QUOTIDIAN ROUTINE AND RELEASE THE INNER YOU.',
                details: 'Details',
                size: '41',
                color: 'pink',
                returns: 'returns info',
            },
        ],
    },
    // ANIMYS Y2
    {
        collection: 'animus-y2',
        title: 'ANIMYS y2',
        price: '$245.00',
        info: [
            {
                id: 1,
                model: '001',
                thumbs: {
                    '1x': 'img/products/ANIMUS-Y2-001/6.jpg',
                    '2x': 'img/products/ANIMUS-Y2-001/5.jpg',
                },
                photos: [
                    {
                        '1x': 'img/products/ANIMUS-Y2-001/2.1.jpg',
                        '2x': 'img/products/ANIMUS-Y2-001/1.1.jpg',
                        mobile: 'img/products/ANIMUS-Y2-001/3.1.jpg',
                        zoomImg: 'img/products/ANIMUS-Y2-001/4.1.jpg',
                    },
                    {
                        '1x': 'img/products/ANIMUS-Y2-001/2.2.jpg',
                        '2x': 'img/products/ANIMUS-Y2-001/1.2.jpg',
                        mobile: 'img/products/ANIMUS-Y2-001/3.2.jpg',
                        zoomImg: 'img/products/ANIMUS-Y2-001/4.2.jpg',
                    },
                    {
                        '1x': 'img/products/ANIMUS-Y2-001/2.3.jpg',
                        '2x': 'img/products/ANIMUS-Y2-001/1.3.jpg',
                        mobile: 'img/products/ANIMUS-Y2-001/3.3.jpg',
                        zoomImg: 'img/products/ANIMUS-Y2-001/4.3.jpg',
                    },
                    {
                        '1x': 'img/products/ANIMUS-Y2-001/2.4.jpg',
                        '2x': 'img/products/ANIMUS-Y2-001/1.4.jpg',
                        mobile: 'img/products/ANIMUS-Y2-001/3.4.jpg',
                        zoomImg: 'img/products/ANIMUS-Y2-001/4.4.jpg',
                    },
                ],
                text: 'METICULOUSLY CRAFTED TO TURN HEADS, THESE OVERSIZED YET GEOMETRICALLY BALANCED FRAMES WITH UNIQUELY ANGLED TEMPLES WERE CRAFTED WITH YOU IN MIND. ESCAPE FROM THE MUNDANE QUOTIDIAN ROUTINE AND RELEASE THE INNER YOU.',
                details: 'Details',
                size: '41',
                color: 'pink',
                returns: 'returns info',
            },
            {
                id: 2,
                model: '002',
                thumbs: {
                    '1x': 'img/products/ANIMUS-Y2-002/6.jpg',
                    '2x': 'img/products/ANIMUS-Y2-002/5.jpg',
                },
                photos: [
                    {
                        '1x': 'img/products/ANIMUS-Y2-002/2.1.jpg',
                        '2x': 'img/products/ANIMUS-Y2-002/1.1.jpg',
                        mobile: 'img/products/ANIMUS-Y2-002/3.1.jpg',
                        zoomImg: 'img/products/ANIMUS-Y2-002/4.1.jpg',
                    },
                    {
                        '1x': 'img/products/ANIMUS-Y2-002/2.2.jpg',
                        '2x': 'img/products/ANIMUS-Y2-002/1.2.jpg',
                        mobile: 'img/products/ANIMUS-Y2-002/3.2.jpg',
                        zoomImg: 'img/products/ANIMUS-Y2-002/4.2.jpg',
                    },
                    {
                        '1x': 'img/products/ANIMUS-Y2-002/2.3.jpg',
                        '2x': 'img/products/ANIMUS-Y2-002/1.3.jpg',
                        mobile: 'img/products/ANIMUS-Y2-002/3.3.jpg',
                        zoomImg: 'img/products/ANIMUS-Y2-002/4.3.jpg',
                    },
                    {
                        '1x': 'img/products/ANIMUS-Y2-002/2.4.jpg',
                        '2x': 'img/products/ANIMUS-Y2-002/1.4.jpg',
                        mobile: 'img/products/ANIMUS-Y2-002/3.4.jpg',
                        zoomImg: 'img/products/ANIMUS-Y2-002/4.4.jpg',
                    },
                ],
                text: 'METICULOUSLY CRAFTED TO TURN HEADS, THESE OVERSIZED YET GEOMETRICALLY BALANCED FRAMES WITH UNIQUELY ANGLED TEMPLES WERE CRAFTED WITH YOU IN MIND. ESCAPE FROM THE MUNDANE QUOTIDIAN ROUTINE AND RELEASE THE INNER YOU.',
                details: 'Details',
                size: '41',
                color: 'pink',
                returns: 'returns info',
            },
            {
                id: 3,
                model: '003',
                thumbs: {
                    '1x': 'img/products/ANIMUS-Y2-003/6.jpg',
                    '2x': 'img/products/ANIMUS-Y2-003/5.jpg',
                },
                photos: [
                    {
                        '1x': 'img/products/ANIMUS-Y2-003/2.1.jpg',
                        '2x': 'img/products/ANIMUS-Y2-003/1.1.jpg',
                        mobile: 'img/products/ANIMUS-Y2-003/3.1.jpg',
                        zoomImg: 'img/products/ANIMUS-Y2-003/4.1.jpg',
                    },
                    {
                        '1x': 'img/products/ANIMUS-Y2-003/2.2.jpg',
                        '2x': 'img/products/ANIMUS-Y2-003/1.2.jpg',
                        mobile: 'img/products/ANIMUS-Y2-003/3.2.jpg',
                        zoomImg: 'img/products/ANIMUS-Y2-003/4.2.jpg',
                    },
                    {
                        '1x': 'img/products/ANIMUS-Y2-003/2.3.jpg',
                        '2x': 'img/products/ANIMUS-Y2-003/1.3.jpg',
                        mobile: 'img/products/ANIMUS-Y2-003/3.3.jpg',
                        zoomImg: 'img/products/ANIMUS-Y2-003/4.3.jpg',
                    },
                    {
                        '1x': 'img/products/ANIMUS-Y2-003/2.4.jpg',
                        '2x': 'img/products/ANIMUS-Y2-003/1.4.jpg',
                        mobile: 'img/products/ANIMUS-Y2-003/3.4.jpg',
                        zoomImg: 'img/products/ANIMUS-Y2-003/4.4.jpg',
                    },
                ],
                text: 'METICULOUSLY CRAFTED TO TURN HEADS, THESE OVERSIZED YET GEOMETRICALLY BALANCED FRAMES WITH UNIQUELY ANGLED TEMPLES WERE CRAFTED WITH YOU IN MIND. ESCAPE FROM THE MUNDANE QUOTIDIAN ROUTINE AND RELEASE THE INNER YOU.',
                details: 'Details',
                size: '41',
                color: 'pink',
                returns: 'returns info',
            },
            {
                id: 4,
                model: '004',
                thumbs: {
                    '1x': 'img/products/ANIMUS-Y2-004/6.jpg',
                    '2x': 'img/products/ANIMUS-Y2-004/5.jpg',
                },
                photos: [
                    {
                        '1x': 'img/products/ANIMUS-Y2-004/2.1.jpg',
                        '2x': 'img/products/ANIMUS-Y2-004/1.1.jpg',
                        mobile: 'img/products/ANIMUS-Y2-004/3.1.jpg',
                        zoomImg: 'img/products/ANIMUS-Y2-004/4.1.jpg',
                    },
                    {
                        '1x': 'img/products/ANIMUS-Y2-004/2.2.jpg',
                        '2x': 'img/products/ANIMUS-Y2-004/1.2.jpg',
                        mobile: 'img/products/ANIMUS-Y2-004/3.2.jpg',
                        zoomImg: 'img/products/ANIMUS-Y2-004/4.2.jpg',
                    },
                    {
                        '1x': 'img/products/ANIMUS-Y2-004/2.3.jpg',
                        '2x': 'img/products/ANIMUS-Y2-004/1.3.jpg',
                        mobile: 'img/products/ANIMUS-Y2-004/3.3.jpg',
                        zoomImg: 'img/products/ANIMUS-Y2-004/4.3.jpg',
                    },
                    {
                        '1x': 'img/products/ANIMUS-Y2-004/2.4.jpg',
                        '2x': 'img/products/ANIMUS-Y2-004/1.4.jpg',
                        mobile: 'img/products/ANIMUS-Y2-004/3.4.jpg',
                        zoomImg: 'img/products/ANIMUS-Y2-004/4.4.jpg',
                    },
                ],
                text: 'METICULOUSLY CRAFTED TO TURN HEADS, THESE OVERSIZED YET GEOMETRICALLY BALANCED FRAMES WITH UNIQUELY ANGLED TEMPLES WERE CRAFTED WITH YOU IN MIND. ESCAPE FROM THE MUNDANE QUOTIDIAN ROUTINE AND RELEASE THE INNER YOU.',
                details: 'Details',
                size: '41',
                color: 'pink',
                returns: 'returns info',
            },
        ],
    },
    // ANIMYS Y3
    {
        collection: 'animus-y3',
        title: 'ANIMUS Y3',
        price: '$245.00',
        info: [
            {
                id: 1,
                model: '001',
                thumbs: {
                    '1x': 'img/product-thumbs/thumbs-4.jpg',
                    '2x': 'img/product-thumbs/thumbs-4-2x.jpg',
                },
                photos: [
                    {
                        '1x': 'img/product-gallery/desktop/Y3_001_1-880.jpg',
                        '2x': 'img/product-gallery/desktop/Y3_001_1-2x.jpg',
                        mobile: 'img/product-gallery/mobile/Y3_001_1-880.jpg',
                        zoomImg: 'img/product-zoom/Y3_001_1-880.jpg',
                    },
                    {
                        '1x': 'img/product-gallery/desktop/Y3_001_2-880.jpg',
                        '2x': 'img/product-gallery/desktop/Y3_001_2-2x.jpg',
                        mobile: 'img/product-gallery/mobile/Y3_001_2-880.jpg',
                        zoomImg: 'img/product-zoom/Y3_001_2-880.jpg',
                    },
                    {
                        '1x': 'img/product-gallery/desktop/Y3_001_3-880.jpg',
                        '2x': 'img/product-gallery/desktop/Y3_001_3-2x.jpg',
                        mobile: 'img/product-gallery/mobile/Y3_001_3-880.jpg',
                        zoomImg: 'img/product-zoom/Y3_001_3-880.jpg',
                    },
                    {
                        '1x': 'img/product-gallery/desktop/Y3_001_10-880.jpg',
                        '2x': 'img/product-gallery/desktop/Y3_001_10-2x.jpg',
                        mobile: 'img/product-gallery/mobile/Y3_001_10-880.jpg',
                        zoomImg: 'img/product-zoom/Y3_001_10-880.jpg',
                    },
                ],
                text: 'METICULOUSLY CRAFTED TO TURN HEADS, THESE OVERSIZED YET GEOMETRICALLY BALANCED FRAMES WITH UNIQUELY ANGLED TEMPLES WERE CRAFTED WITH YOU IN MIND. ESCAPE FROM THE MUNDANE QUOTIDIAN ROUTINE AND RELEASE THE INNER YOU.',
                details: 'Details',
                size: '41',
                color: 'pink',
                returns: 'returns info',
            },
            {
                id: 2,
                model: '002',
                thumbs: {
                    '1x': 'img/product-thumbs/thumbs-2.jpg',
                    '2x': 'img/product-thumbs/thumbs-2-2x.jpg',
                },
                photos: [
                    {
                        '1x': 'img/product-gallery/desktop/Y3_002_1-880.jpg',
                        '2x': 'img/product-gallery/desktop/Y3_002_1-2x.jpg',
                        mobile: 'img/product-gallery/mobile/Y3_002_1-880.jpg',
                        zoomImg: 'img/product-zoom/Y3_002_1-880.jpg',
                    },
                    {
                        '1x': 'img/product-gallery/desktop/Y3_002_2-880.jpg',
                        '2x': 'img/product-gallery/desktop/Y3_002_2-2x.jpg',
                        mobile: 'img/product-gallery/mobile/Y3_002_2-880.jpg',
                        zoomImg: 'img/product-zoom/Y3_002_2-880.jpg',
                    },
                    {
                        '1x': 'img/product-gallery/desktop/Y3_002_3-880.jpg',
                        '2x': 'img/product-gallery/desktop/Y3_002_3-2x.jpg',
                        mobile: 'img/product-gallery/mobile/Y3_002_3-880.jpg',
                        zoomImg: 'img/product-zoom/Y3_002_3-880.jpg',
                    },
                    {
                        '1x': 'img/product-gallery/desktop/Y3_002_10-880.jpg',
                        '2x': 'img/product-gallery/desktop/Y3_002_10-2x.jpg',
                        mobile: 'img/product-gallery/mobile/Y3_002_4-880.jpg',
                        zoomImg: 'img/product-zoom/Y3_002_10-880.jpg',
                    },
                ],
                text: 'METICULOUSLY CRAFTED TO TURN HEADS, THESE OVERSIZED YET GEOMETRICALLY BALANCED FRAMES WITH UNIQUELY ANGLED TEMPLES WERE CRAFTED WITH YOU IN MIND. ESCAPE FROM THE MUNDANE QUOTIDIAN ROUTINE AND RELEASE THE INNER YOU.',
                details: 'Details',
                size: '43',
                color: 'orange',
                returns: 'returns info',
            },
            {
                id: 3,
                model: '003',
                thumbs: {
                    '1x': 'img/product-thumbs/thumbs-3.jpg',
                    '2x': 'img/product-thumbs/thumbs-3-2x.jpg',
                },
                photos: [
                    {
                        '1x': 'img/product-gallery/desktop/Y3_003_1-880.jpg',
                        '2x': 'img/product-gallery/desktop/Y3_003_1-2x.jpg',
                        mobile: 'img/product-gallery/mobile/Y3_003_1-880.jpg',
                        zoomImg: 'img/product-zoom/Y3_003_1-880.jpg',
                    },
                    {
                        '1x': 'img/product-gallery/desktop/Y3_003_2-880.jpg',
                        '2x': 'img/product-gallery/desktop/Y3_003_2-2x.jpg',
                        mobile: 'img/product-gallery/mobile/Y3_003_2-880.jpg',
                        zoomImg: 'img/product-zoom/Y3_003_2-880.jpg',
                    },
                    {
                        '1x': 'img/product-gallery/desktop/Y3_003_3-880.jpg',
                        '2x': 'img/product-gallery/desktop/Y3_003_3-2x.jpg',
                        mobile: 'img/product-gallery/mobile/Y3_003_3-880.jpg',
                        zoomImg: 'img/product-zoom/Y3_003_3-880.jpg',
                    },
                    {
                        '1x': 'img/product-gallery/desktop/Y3_003_10-880.jpg',
                        '2x': 'img/product-gallery/desktop/Y3_003_10-2x.jpg',
                        mobile: 'img/product-gallery/mobile/Y3_003_4-880.jpg',
                        zoomImg: 'img/product-zoom/Y3_003_10-880.jpg',
                    },
                ],
                text: 'METICULOUSLY CRAFTED TO TURN HEADS, THESE OVERSIZED YET GEOMETRICALLY BALANCED FRAMES WITH UNIQUELY ANGLED TEMPLES WERE CRAFTED WITH YOU IN MIND. ESCAPE FROM THE MUNDANE QUOTIDIAN ROUTINE AND RELEASE THE INNER YOU.',
                details: 'Details',
                size: '44',
                color: 'green',
                returns: 'returns info',
            },
            {
                id: 4,
                model: '004',
                thumbs: {
                    '1x': 'img/product-thumbs/thumbs-1.jpg',
                    '2x': 'img/product-thumbs/thumbs-1-2x.jpg',
                },
                photos: [
                    {
                        '1x': 'img/product-gallery/desktop/Y3_004_1-880.jpg',
                        '2x': 'img/product-gallery/desktop/Y3_004_1-2x.jpg',
                        mobile: 'img/product-gallery/mobile/Y3_004_1-880.jpg',
                        zoomImg: 'img/product-zoom/Y3_004_1-880.jpg',
                    },
                    {
                        '1x': 'img/product-gallery/desktop/Y3_004_2-880.jpg',
                        '2x': 'img/product-gallery/desktop/Y3_004_2-2x.jpg',
                        mobile: 'img/product-gallery/mobile/Y3_004_2-880.jpg',
                        zoomImg: 'img/product-zoom/Y3_004_2-880.jpg',
                    },
                    {
                        '1x': 'img/product-gallery/desktop/Y3_004_3-880.jpg',
                        '2x': 'img/product-gallery/desktop/Y3_004_3-2x.jpg',
                        mobile: 'img/product-gallery/mobile/Y3_004_3-880.jpg',
                        zoomImg: 'img/product-zoom/Y3_004_3-880.jpg',
                    },
                    {
                        '1x': 'img/product-gallery/desktop/Y3_004_10-880.jpg',
                        '2x': 'img/product-gallery/desktop/Y3_004_10-2x.jpg',
                        mobile: 'img/product-gallery/mobile/Y3_004_4-880.jpg',
                        zoomImg: 'img/product-zoom/Y3_004_10-880.jpg',
                    },
                ],
                text: 'METICULOUSLY CRAFTED TO TURN HEADS, THESE OVERSIZED YET GEOMETRICALLY BALANCED FRAMES WITH UNIQUELY ANGLED TEMPLES WERE CRAFTED WITH YOU IN MIND. ESCAPE FROM THE MUNDANE QUOTIDIAN ROUTINE AND RELEASE THE INNER YOU.',
                details: 'Details',
                size: '42',
                color: 'blue',
                returns: 'returns info',
            },
        ],
    },
    // ANIMA X1
    {
        collection: 'anima-x1',
        title: 'ANIMA x1',
        price: '$245.00',
        info: [
            {
                id: 1,
                model: '001',
                thumbs: {
                    '1x': 'img/products/ANIMA-X1-001/6.jpg',
                    '2x': 'img/products/ANIMA-X1-001/5.jpg',
                },
                photos: [
                    {
                        '1x': 'img/products/ANIMA-X1-001/2.1.jpg',
                        '2x': 'img/products/ANIMA-X1-001/1.1.jpg',
                        mobile: 'img/products/ANIMA-X1-001/3.1.jpg',
                        zoomImg: 'img/products/ANIMA-X1-001/4.1.jpg',
                    },
                    {
                        '1x': 'img/products/ANIMA-X1-001/2.2.jpg',
                        '2x': 'img/products/ANIMA-X1-001/1.2.jpg',
                        mobile: 'img/products/ANIMA-X1-001/3.2.jpg',
                        zoomImg: 'img/products/ANIMA-X1-001/4.2.jpg',
                    },
                    {
                        '1x': 'img/products/ANIMA-X1-001/2.3.jpg',
                        '2x': 'img/products/ANIMA-X1-001/1.3.jpg',
                        mobile: 'img/products/ANIMA-X1-001/3.3.jpg',
                        zoomImg: 'img/products/ANIMA-X1-001/4.3.jpg',
                    },
                    {
                        '1x': 'img/products/ANIMA-X1-001/2.4.jpg',
                        '2x': 'img/products/ANIMA-X1-001/1.4.jpg',
                        mobile: 'img/products/ANIMA-X1-001/3.4.jpg',
                        zoomImg: 'img/products/ANIMA-X1-001/4.4.jpg',
                    },
                ],
                text: 'METICULOUSLY CRAFTED TO TURN HEADS, THESE OVERSIZED YET GEOMETRICALLY BALANCED FRAMES WITH UNIQUELY ANGLED TEMPLES WERE CRAFTED WITH YOU IN MIND. ESCAPE FROM THE MUNDANE QUOTIDIAN ROUTINE AND RELEASE THE INNER YOU.',
                details: 'Details',
                size: '41',
                color: 'pink',
                returns: 'returns info',
            },
            {
                id: 2,
                model: '002',
                thumbs: {
                    '1x': 'img/products/ANIMA-X1-002/6.jpg',
                    '2x': 'img/products/ANIMA-X1-002/5.jpg',
                },
                photos: [
                    {
                        '1x': 'img/products/ANIMA-X1-002/2.1.jpg',
                        '2x': 'img/products/ANIMA-X1-002/1.1.jpg',
                        mobile: 'img/products/ANIMA-X1-002/3.1.jpg',
                        zoomImg: 'img/products/ANIMA-X1-002/4.1.jpg',
                    },
                    {
                        '1x': 'img/products/ANIMA-X1-002/2.2.jpg',
                        '2x': 'img/products/ANIMA-X1-002/1.2.jpg',
                        mobile: 'img/products/ANIMA-X1-002/3.2.jpg',
                        zoomImg: 'img/products/ANIMA-X1-002/4.2.jpg',
                    },
                    {
                        '1x': 'img/products/ANIMA-X1-002/2.3.jpg',
                        '2x': 'img/products/ANIMA-X1-002/1.3.jpg',
                        mobile: 'img/products/ANIMA-X1-002/3.3.jpg',
                        zoomImg: 'img/products/ANIMA-X1-002/4.3.jpg',
                    },
                    {
                        '1x': 'img/products/ANIMA-X1-002/2.4.jpg',
                        '2x': 'img/products/ANIMA-X1-002/1.4.jpg',
                        mobile: 'img/products/ANIMA-X1-002/3.4.jpg',
                        zoomImg: 'img/products/ANIMA-X1-002/4.4.jpg',
                    },
                ],
                text: 'METICULOUSLY CRAFTED TO TURN HEADS, THESE OVERSIZED YET GEOMETRICALLY BALANCED FRAMES WITH UNIQUELY ANGLED TEMPLES WERE CRAFTED WITH YOU IN MIND. ESCAPE FROM THE MUNDANE QUOTIDIAN ROUTINE AND RELEASE THE INNER YOU.',
                details: 'Details',
                size: '41',
                color: 'pink',
                returns: 'returns info',
            },
            {
                id: 3,
                model: '003',
                thumbs: {
                    '1x': 'img/products/ANIMA-X1-003/6.jpg',
                    '2x': 'img/products/ANIMA-X1-003/5.jpg',
                },
                photos: [
                    {
                        '1x': 'img/products/ANIMA-X1-003/2.1.jpg',
                        '2x': 'img/products/ANIMA-X1-003/1.1.jpg',
                        mobile: 'img/products/ANIMA-X1-003/3.1.jpg',
                        zoomImg: 'img/products/ANIMA-X1-003/4.1.jpg',
                    },
                    {
                        '1x': 'img/products/ANIMA-X1-003/2.2.jpg',
                        '2x': 'img/products/ANIMA-X1-003/1.2.jpg',
                        mobile: 'img/products/ANIMA-X1-003/3.2.jpg',
                        zoomImg: 'img/products/ANIMA-X1-003/4.2.jpg',
                    },
                    {
                        '1x': 'img/products/ANIMA-X1-003/2.3.jpg',
                        '2x': 'img/products/ANIMA-X1-003/1.3.jpg',
                        mobile: 'img/products/ANIMA-X1-003/3.3.jpg',
                        zoomImg: 'img/products/ANIMA-X1-003/4.3.jpg',
                    },
                    {
                        '1x': 'img/products/ANIMA-X1-003/2.4.jpg',
                        '2x': 'img/products/ANIMA-X1-003/1.4.jpg',
                        mobile: 'img/products/ANIMA-X1-003/3.4.jpg',
                        zoomImg: 'img/products/ANIMA-X1-003/4.4.jpg',
                    },
                ],
                text: 'METICULOUSLY CRAFTED TO TURN HEADS, THESE OVERSIZED YET GEOMETRICALLY BALANCED FRAMES WITH UNIQUELY ANGLED TEMPLES WERE CRAFTED WITH YOU IN MIND. ESCAPE FROM THE MUNDANE QUOTIDIAN ROUTINE AND RELEASE THE INNER YOU.',
                details: 'Details',
                size: '41',
                color: 'pink',
                returns: 'returns info',
            },
            {
                id: 4,
                model: '004',
                thumbs: {
                    '1x': 'img/products/ANIMA-X1-004/6.jpg',
                    '2x': 'img/products/ANIMA-X1-004/5.jpg',
                },
                photos: [
                    {
                        '1x': 'img/products/ANIMA-X1-004/2.1.jpg',
                        '2x': 'img/products/ANIMA-X1-004/1.1.jpg',
                        mobile: 'img/products/ANIMA-X1-004/3.1.jpg',
                        zoomImg: 'img/products/ANIMA-X1-004/4.1.jpg',
                    },
                    {
                        '1x': 'img/products/ANIMA-X1-004/2.2.jpg',
                        '2x': 'img/products/ANIMA-X1-004/1.2.jpg',
                        mobile: 'img/products/ANIMA-X1-004/3.2.jpg',
                        zoomImg: 'img/products/ANIMA-X1-004/4.2.jpg',
                    },
                    {
                        '1x': 'img/products/ANIMA-X1-004/2.3.jpg',
                        '2x': 'img/products/ANIMA-X1-004/1.3.jpg',
                        mobile: 'img/products/ANIMA-X1-004/3.3.jpg',
                        zoomImg: 'img/products/ANIMA-X1-004/4.3.jpg',
                    },
                    {
                        '1x': 'img/products/ANIMA-X1-004/2.4.jpg',
                        '2x': 'img/products/ANIMA-X1-004/1.4.jpg',
                        mobile: 'img/products/ANIMA-X1-004/3.4.jpg',
                        zoomImg: 'img/products/ANIMA-X1-004/4.4.jpg',
                    },
                ],
                text: 'METICULOUSLY CRAFTED TO TURN HEADS, THESE OVERSIZED YET GEOMETRICALLY BALANCED FRAMES WITH UNIQUELY ANGLED TEMPLES WERE CRAFTED WITH YOU IN MIND. ESCAPE FROM THE MUNDANE QUOTIDIAN ROUTINE AND RELEASE THE INNER YOU.',
                details: 'Details',
                size: '41',
                color: 'pink',
                returns: 'returns info',
            },
        ],
    },
    // ANIMA X2
    {
        collection: 'anima-x2',
        title: 'ANIMA x2',
        price: '$245.00',
        info: [
            {
                id: 1,
                model: '001',
                thumbs: {
                    '1x': 'img/products/ANIMA-X2-001/6.jpg',
                    '2x': 'img/products/ANIMA-X2-001/5.jpg',
                },
                photos: [
                    {
                        '1x': 'img/products/ANIMA-X2-001/2.1.jpg',
                        '2x': 'img/products/ANIMA-X2-001/1.1.jpg',
                        mobile: 'img/products/ANIMA-X2-001/3.1.jpg',
                        zoomImg: 'img/products/ANIMA-X2-001/4.1.jpg',
                    },
                    {
                        '1x': 'img/products/ANIMA-X2-001/2.2.jpg',
                        '2x': 'img/products/ANIMA-X2-001/1.2.jpg',
                        mobile: 'img/products/ANIMA-X2-001/3.2.jpg',
                        zoomImg: 'img/products/ANIMA-X2-001/4.2.jpg',
                    },
                    {
                        '1x': 'img/products/ANIMA-X2-001/2.3.jpg',
                        '2x': 'img/products/ANIMA-X2-001/1.3.jpg',
                        mobile: 'img/products/ANIMA-X2-001/3.3.jpg',
                        zoomImg: 'img/products/ANIMA-X2-001/4.3.jpg',
                    },
                    {
                        '1x': 'img/products/ANIMA-X2-001/2.4.jpg',
                        '2x': 'img/products/ANIMA-X2-001/1.4.jpg',
                        mobile: 'img/products/ANIMA-X2-001/3.4.jpg',
                        zoomImg: 'img/products/ANIMA-X2-001/4.4.jpg',
                    },
                ],
                text: 'METICULOUSLY CRAFTED TO TURN HEADS, THESE OVERSIZED YET GEOMETRICALLY BALANCED FRAMES WITH UNIQUELY ANGLED TEMPLES WERE CRAFTED WITH YOU IN MIND. ESCAPE FROM THE MUNDANE QUOTIDIAN ROUTINE AND RELEASE THE INNER YOU.',
                details: 'Details',
                size: '41',
                color: 'pink',
                returns: 'returns info',
            },
            {
                id: 2,
                model: '002',
                thumbs: {
                    '1x': 'img/products/ANIMA-X2-002/6.jpg',
                    '2x': 'img/products/ANIMA-X2-002/5.jpg',
                },
                photos: [
                    {
                        '1x': 'img/products/ANIMA-X2-002/2.1.jpg',
                        '2x': 'img/products/ANIMA-X2-002/1.1.jpg',
                        mobile: 'img/products/ANIMA-X2-002/3.1.jpg',
                        zoomImg: 'img/products/ANIMA-X2-002/4.1.jpg',
                    },
                    {
                        '1x': 'img/products/ANIMA-X2-002/2.2.jpg',
                        '2x': 'img/products/ANIMA-X2-002/1.2.jpg',
                        mobile: 'img/products/ANIMA-X2-002/3.2.jpg',
                        zoomImg: 'img/products/ANIMA-X2-002/4.2.jpg',
                    },
                    {
                        '1x': 'img/products/ANIMA-X2-002/2.3.jpg',
                        '2x': 'img/products/ANIMA-X2-002/1.3.jpg',
                        mobile: 'img/products/ANIMA-X2-002/3.3.jpg',
                        zoomImg: 'img/products/ANIMA-X2-002/4.3.jpg',
                    },
                    {
                        '1x': 'img/products/ANIMA-X2-002/2.4.jpg',
                        '2x': 'img/products/ANIMA-X2-002/1.4.jpg',
                        mobile: 'img/products/ANIMA-X2-002/3.4.jpg',
                        zoomImg: 'img/products/ANIMA-X2-002/4.4.jpg',
                    },
                ],
                text: 'METICULOUSLY CRAFTED TO TURN HEADS, THESE OVERSIZED YET GEOMETRICALLY BALANCED FRAMES WITH UNIQUELY ANGLED TEMPLES WERE CRAFTED WITH YOU IN MIND. ESCAPE FROM THE MUNDANE QUOTIDIAN ROUTINE AND RELEASE THE INNER YOU.',
                details: 'Details',
                size: '41',
                color: 'pink',
                returns: 'returns info',
            },
            {
                id: 3,
                model: '003',
                thumbs: {
                    '1x': 'img/products/ANIMA-X2-003/6.jpg',
                    '2x': 'img/products/ANIMA-X2-003/5.jpg',
                },
                photos: [
                    {
                        '1x': 'img/products/ANIMA-X2-003/2.1.jpg',
                        '2x': 'img/products/ANIMA-X2-003/1.1.jpg',
                        mobile: 'img/products/ANIMA-X2-003/3.1.jpg',
                        zoomImg: 'img/products/ANIMA-X2-003/4.1.jpg',
                    },
                    {
                        '1x': 'img/products/ANIMA-X2-003/2.2.jpg',
                        '2x': 'img/products/ANIMA-X2-003/1.2.jpg',
                        mobile: 'img/products/ANIMA-X2-003/3.2.jpg',
                        zoomImg: 'img/products/ANIMA-X2-003/4.2.jpg',
                    },
                    {
                        '1x': 'img/products/ANIMA-X2-003/2.3.jpg',
                        '2x': 'img/products/ANIMA-X2-003/1.3.jpg',
                        mobile: 'img/products/ANIMA-X2-003/3.3.jpg',
                        zoomImg: 'img/products/ANIMA-X2-003/4.3.jpg',
                    },
                    {
                        '1x': 'img/products/ANIMA-X2-003/2.4.jpg',
                        '2x': 'img/products/ANIMA-X2-003/1.4.jpg',
                        mobile: 'img/products/ANIMA-X2-003/3.4.jpg',
                        zoomImg: 'img/products/ANIMA-X2-003/4.4.jpg',
                    },
                ],
                text: 'METICULOUSLY CRAFTED TO TURN HEADS, THESE OVERSIZED YET GEOMETRICALLY BALANCED FRAMES WITH UNIQUELY ANGLED TEMPLES WERE CRAFTED WITH YOU IN MIND. ESCAPE FROM THE MUNDANE QUOTIDIAN ROUTINE AND RELEASE THE INNER YOU.',
                details: 'Details',
                size: '41',
                color: 'pink',
                returns: 'returns info',
            },
            {
                id: 4,
                model: '004',
                thumbs: {
                    '1x': 'img/products/ANIMA-X2-004/6.jpg',
                    '2x': 'img/products/ANIMA-X2-004/5.jpg',
                },
                photos: [
                    {
                        '1x': 'img/products/ANIMA-X2-004/2.1.jpg',
                        '2x': 'img/products/ANIMA-X2-004/1.1.jpg',
                        mobile: 'img/products/ANIMA-X2-004/3.1.jpg',
                        zoomImg: 'img/products/ANIMA-X2-004/4.1.jpg',
                    },
                    {
                        '1x': 'img/products/ANIMA-X2-004/2.2.jpg',
                        '2x': 'img/products/ANIMA-X2-004/1.2.jpg',
                        mobile: 'img/products/ANIMA-X2-004/3.2.jpg',
                        zoomImg: 'img/products/ANIMA-X2-004/4.2.jpg',
                    },
                    {
                        '1x': 'img/products/ANIMA-X2-004/2.3.jpg',
                        '2x': 'img/products/ANIMA-X2-004/1.3.jpg',
                        mobile: 'img/products/ANIMA-X2-004/3.3.jpg',
                        zoomImg: 'img/products/ANIMA-X2-004/4.3.jpg',
                    },
                    {
                        '1x': 'img/products/ANIMA-X2-004/2.4.jpg',
                        '2x': 'img/products/ANIMA-X2-004/1.4.jpg',
                        mobile: 'img/products/ANIMA-X2-004/3.4.jpg',
                        zoomImg: 'img/products/ANIMA-X2-004/4.4.jpg',
                    },
                ],
                text: 'METICULOUSLY CRAFTED TO TURN HEADS, THESE OVERSIZED YET GEOMETRICALLY BALANCED FRAMES WITH UNIQUELY ANGLED TEMPLES WERE CRAFTED WITH YOU IN MIND. ESCAPE FROM THE MUNDANE QUOTIDIAN ROUTINE AND RELEASE THE INNER YOU.',
                details: 'Details',
                size: '41',
                color: 'pink',
                returns: 'returns info',
            },
        ],
    },
    // ANIMA X3
    {
        collection: 'anima-x3',
        title: 'ANIMA x3',
        price: '$245.00',
        info: [
            {
                id: 1,
                model: '001',
                thumbs: {
                    '1x': 'img/products/ANIMA-X3-001/6.jpg',
                    '2x': 'img/products/ANIMA-X3-001/5.jpg',
                },
                photos: [
                    {
                        '1x': 'img/products/ANIMA-X3-001/2.1.jpg',
                        '2x': 'img/products/ANIMA-X3-001/1.1.jpg',
                        mobile: 'img/products/ANIMA-X3-001/3.1.jpg',
                        zoomImg: 'img/products/ANIMA-X3-001/4.1.jpg',
                    },
                    {
                        '1x': 'img/products/ANIMA-X3-001/2.2.jpg',
                        '2x': 'img/products/ANIMA-X3-001/1.2.jpg',
                        mobile: 'img/products/ANIMA-X3-001/3.2.jpg',
                        zoomImg: 'img/products/ANIMA-X3-001/4.2.jpg',
                    },
                    {
                        '1x': 'img/products/ANIMA-X3-001/2.3.jpg',
                        '2x': 'img/products/ANIMA-X3-001/1.3.jpg',
                        mobile: 'img/products/ANIMA-X3-001/3.3.jpg',
                        zoomImg: 'img/products/ANIMA-X3-001/4.3.jpg',
                    },
                    {
                        '1x': 'img/products/ANIMA-X3-001/2.4.jpg',
                        '2x': 'img/products/ANIMA-X3-001/1.4.jpg',
                        mobile: 'img/products/ANIMA-X3-001/3.4.jpg',
                        zoomImg: 'img/products/ANIMA-X3-001/4.4.jpg',
                    },
                ],
                text: 'METICULOUSLY CRAFTED TO TURN HEADS, THESE OVERSIZED YET GEOMETRICALLY BALANCED FRAMES WITH UNIQUELY ANGLED TEMPLES WERE CRAFTED WITH YOU IN MIND. ESCAPE FROM THE MUNDANE QUOTIDIAN ROUTINE AND RELEASE THE INNER YOU.',
                details: 'Details',
                size: '41',
                color: 'pink',
                returns: 'returns info',
            },
            {
                id: 2,
                model: '002',
                thumbs: {
                    '1x': 'img/products/ANIMA-X3-002/6.jpg',
                    '2x': 'img/products/ANIMA-X3-002/5.jpg',
                },
                photos: [
                    {
                        '1x': 'img/products/ANIMA-X3-002/2.1.jpg',
                        '2x': 'img/products/ANIMA-X3-002/1.1.jpg',
                        mobile: 'img/products/ANIMA-X3-002/3.1.jpg',
                        zoomImg: 'img/products/ANIMA-X3-002/4.1.jpg',
                    },
                    {
                        '1x': 'img/products/ANIMA-X3-002/2.2.jpg',
                        '2x': 'img/products/ANIMA-X3-002/1.2.jpg',
                        mobile: 'img/products/ANIMA-X3-002/3.2.jpg',
                        zoomImg: 'img/products/ANIMA-X3-002/4.2.jpg',
                    },
                    {
                        '1x': 'img/products/ANIMA-X3-002/2.3.jpg',
                        '2x': 'img/products/ANIMA-X3-002/1.3.jpg',
                        mobile: 'img/products/ANIMA-X3-002/3.3.jpg',
                        zoomImg: 'img/products/ANIMA-X3-002/4.3.jpg',
                    },
                    {
                        '1x': 'img/products/ANIMA-X3-002/2.4.jpg',
                        '2x': 'img/products/ANIMA-X3-002/1.4.jpg',
                        mobile: 'img/products/ANIMA-X3-002/3.4.jpg',
                        zoomImg: 'img/products/ANIMA-X3-002/4.4.jpg',
                    },
                ],
                text: 'METICULOUSLY CRAFTED TO TURN HEADS, THESE OVERSIZED YET GEOMETRICALLY BALANCED FRAMES WITH UNIQUELY ANGLED TEMPLES WERE CRAFTED WITH YOU IN MIND. ESCAPE FROM THE MUNDANE QUOTIDIAN ROUTINE AND RELEASE THE INNER YOU.',
                details: 'Details',
                size: '41',
                color: 'pink',
                returns: 'returns info',
            },
            {
                id: 3,
                model: '003',
                thumbs: {
                    '1x': 'img/products/ANIMA-X3-003/6.jpg',
                    '2x': 'img/products/ANIMA-X3-003/5.jpg',
                },
                photos: [
                    {
                        '1x': 'img/products/ANIMA-X3-003/2.1.jpg',
                        '2x': 'img/products/ANIMA-X3-003/1.1.jpg',
                        mobile: 'img/products/ANIMA-X3-003/3.1.jpg',
                        zoomImg: 'img/products/ANIMA-X3-003/4.1.jpg',
                    },
                    {
                        '1x': 'img/products/ANIMA-X3-003/2.2.jpg',
                        '2x': 'img/products/ANIMA-X3-003/1.2.jpg',
                        mobile: 'img/products/ANIMA-X3-003/3.2.jpg',
                        zoomImg: 'img/products/ANIMA-X3-003/4.2.jpg',
                    },
                    {
                        '1x': 'img/products/ANIMA-X3-003/2.3.jpg',
                        '2x': 'img/products/ANIMA-X3-003/1.3.jpg',
                        mobile: 'img/products/ANIMA-X3-003/3.3.jpg',
                        zoomImg: 'img/products/ANIMA-X3-003/4.3.jpg',
                    },
                    {
                        '1x': 'img/products/ANIMA-X3-003/2.4.jpg',
                        '2x': 'img/products/ANIMA-X3-003/1.4.jpg',
                        mobile: 'img/products/ANIMA-X3-003/3.4.jpg',
                        zoomImg: 'img/products/ANIMA-X3-003/4.4.jpg',
                    },
                ],
                text: 'METICULOUSLY CRAFTED TO TURN HEADS, THESE OVERSIZED YET GEOMETRICALLY BALANCED FRAMES WITH UNIQUELY ANGLED TEMPLES WERE CRAFTED WITH YOU IN MIND. ESCAPE FROM THE MUNDANE QUOTIDIAN ROUTINE AND RELEASE THE INNER YOU.',
                details: 'Details',
                size: '41',
                color: 'pink',
                returns: 'returns info',
            },
        ],
    },
];
