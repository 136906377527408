/* eslint-disable */

import { getElementByDataType } from '../lib/utils';
import blogAnimation from './blog';

export function sortArticles() {
    const $select = getElementByDataType('sort-articles-select');
    const $articlesContainer = getElementByDataType('blog_articles');
    const $mockArticleItems = $articlesContainer.innerHTML;

    const sortArticlesHandler = e => {
        $articlesContainer.innerHTML = $mockArticleItems;

        $articlesContainer.querySelectorAll('[data-post-type]').forEach(article => {
            const { postType } = article.dataset;
            const isEquals = postType === e.target.value;

            if (!isEquals && e.target.value !== 'all') {
                article.remove();
            }
        });

        blogAnimation(true);
    };

    $select.addEventListener('change', sortArticlesHandler);
}
