/* eslint-disable */
import uniq from 'lodash/uniq';

const disabledScripts = [
    'app.js',
    'cart.js',
    'cart-drawer.js',
    'search-form.js',
    'predictive-search.js',
];

function updateSctipts() {
    const $scripts = document.querySelectorAll('body script[src]');
    const srcScripts = [];

    $scripts.forEach($s => {
        const scriptName = disabledScripts.find(name => $s.src.match(name) !== null);
        if (scriptName) return;

        srcScripts.push($s.src);

        $s.remove();
    });

    uniq(srcScripts).forEach(src => {
        const $script = document.createElement('script');
        $script.src = src;
        document.body.appendChild($script);
    });
}

export default updateSctipts;
