import { sStorage } from '../lib/storage';
import initProductScale from './initProductScale';

const filterOut = () => {
    const categories = document.querySelector('.categories');
    const filter = document.querySelector('.filter__select');
    const filterLink = document.querySelector('.js-filter-link');
    const overlapSlider = document.querySelector('.page-overlap');

    const visibilityHandler = (showEl = [], hideEl = [], className = 'isHidden') => {
        [...hideEl].map(el => el.classList.add(className));
        [...showEl].map(el => el.classList.remove(className));
    };

    const removePlugs = () => {
        const plugEls = document.querySelectorAll('.js-plug');
        if (plugEls.length > 0) {
            plugEls.forEach(el => {
                el.remove();
            });
        }
    };

    const checkProducts = async optionValue => {
        const productEl = [...document.querySelectorAll('a[data-collection]')];
        const plug = `<a href="#" class="js-plug swiper-slide product__item" data-collection="${optionValue}"></a>`;
        const filterValue = optionValue;

        const allFilterValue = filter[0].value;

        if (filterValue === allFilterValue) {
            removePlugs();
        }

        const makeArray = () => {
            const currSlider = document.querySelector('.js-hero-slider');

            let filteredEl =
                filterValue === allFilterValue
                    ? productEl
                    : [...document.querySelectorAll(`a[data-collection="${filterValue}"]`)];

            if (filteredEl.length < 4 && !categories) {
                while (filteredEl.length < 4) {
                    currSlider.insertAdjacentHTML('beforeend', plug);
                    filteredEl = [
                        ...document.querySelectorAll(`a[data-collection="${filterValue}"]`),
                    ];
                }
            }

            return filteredEl;
        };

        const shownEl = await makeArray();

        visibilityHandler(shownEl, productEl);

        if (!categories) {
            window.swiperProductList.update();
        }
    };

    if (sStorage('filter')) {
        filter.value = sStorage('filter');
        checkProducts(filter.value);
    }

    const setUrl = url => {
        filterLink.href = `${url}`;
    };

    window.changeFilter = function changeFilter(option) {
        const optionValue = option.value;

        // if (categories) {
        sStorage('filter', optionValue);
        // }

        if (overlapSlider) {
            setUrl(option.dataset.url);
        }

        initProductScale();
        checkProducts(optionValue);

        if (!categories) {
            window.swiperProductList.update();
        }
    };
};
export default filterOut;
