import gsap from 'gsap';

const stickyCursorMove = () => {
    const stickyTarget = document.querySelectorAll('.js-sticky-target');

    if (
        !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    ) {
        stickyTarget.forEach((item, index) => {
            const stickyText = document.querySelectorAll('.js-sticky-text');

            gsap.set(stickyText[index], {
                x: item.offsetWidth / 2,
                y:
                    item.offsetHeight > window.innerHeight
                        ? window.innerHeight / 2
                        : item.offsetHeight / 2,
            });
            let mousePos = {
                x: 0,
                y: 0,
            };

            const initAnim = event => {
                const { target } = event;
                const eventCameFromScroll = event.clientX === undefined;
                const bounds = item.getBoundingClientRect();
                if (!eventCameFromScroll) {
                    mousePos = {
                        x: event.clientX,
                        y: event.clientY,
                    };
                }
                const currentPos = {
                    x: mousePos.x,
                    y: mousePos.y - bounds.top,
                };
                const duration = eventCameFromScroll ? 0.1 : 0.7;
                if(eventCameFromScroll) {
                  gsap.set(
                    stickyText[index],
                    {
                      x: currentPos.x - stickyText[index].offsetWidth / 2,
                      y: currentPos.y - stickyText[index].offsetHeight / 2,
                    })
                } else{

                  gsap.to(stickyText[index], {
                      duration,
                      x: currentPos.x - stickyText[index].offsetWidth / 2,
                      y: currentPos.y - stickyText[index].offsetHeight / 2,
                  });
                }
                

                if (!eventCameFromScroll) {
                    if (target.closest('img.js-slider')) {
                        stickyText[index].textContent = target.alt;
                    }

                    if (target.closest('.js-sticky-visible')) {
                        gsap.to(stickyText[index], { opacity: 1 });
                    } else {
                        gsap.to(stickyText[index], { opacity: 0 });
                    }
                }
            };

            item.addEventListener('mouseleave', () => {
                gsap.to(stickyText[index], { opacity: 0 });
            });
            document.addEventListener('scroll-move', initAnim);
            item.addEventListener('mousemove', initAnim);
            item.addEventListener('mouseenter', initAnim);
            item.addEventListener('pointerover', initAnim);
            item.addEventListener('pointermove', initAnim);
            // item.addEventListener('mousewheel', e => {
            //     initAnim(e);
            // });
            item.addEventListener('touchmove', () => {});
        });
    }
};

export default stickyCursorMove;
