const heroPlay = () => {
    const hero = document.querySelector('.js-hero-video');
    const heroLong = document.querySelector('.video-long');
    const sticky = hero.parentNode.querySelector('.js-sticky-text');

    const playFull = () => {
        hero.removeAttribute('autoplay');
        hero.removeAttribute('data-short');
        heroLong.load();
        heroLong.play();
        setTimeout(() => {
            hero.style.opacity = '0';
            hero.style.zIndex = '-10';
            hero.pause();
            heroLong.muted = false;
        }, 800);
        heroLong.setAttribute('data-play', true);
        sticky.textContent = 'pause';
    };

    const videoControl = () => {
        if (heroLong.hasAttribute('data-play')) {
            heroLong.pause();
            heroLong.removeAttribute('data-play');
            heroLong.setAttribute('data-pause', true);
            sticky.textContent = 'play';
        } else if (heroLong.hasAttribute('data-pause')) {
            heroLong.play();
            heroLong.setAttribute('data-play', true);
            heroLong.removeAttribute('data-pause');
            sticky.textContent = 'pause';
        }
    };

    if (hero) {
        hero.addEventListener('click', playFull);
    }
    if (heroLong) {
        heroLong.addEventListener('click', videoControl);
    }
};

export default heroPlay;
