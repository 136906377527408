/* eslint-disable */

import barba from '@barba/core';
import gsap from 'gsap';
import { Expo } from 'gsap/all';
import 'regenerator-runtime/runtime';

import ScrollTrigger from 'gsap/ScrollTrigger';
import initProduct from './initProduct';
import changeModel from './initGallery';
import blogAnimation from './blog';
import initSwiperProduct from './initSwiperProduct';
import validation from './validation';
import heroPlay from './hero';
import overlap from './overlap';
import { enableScroll } from '../lib/handleScrollBody';
import stickyCursorMove from './stickyCursor';
import copyLink from './copyLink';
import initProductScale from './initProductScale';
import filterOut from './filterOut';
import initLinkHoverAnimation from './initLinkHoverAnimation';
import imageZoom from './imageZoom';
import footerHover from './footer';
import updateSctipts from './updateScripts';
import poductToggles from './productToggles';
import { fetchDoubleProducts, fetchProductGallery, fetchProducts } from '../api/fetchProducts';
import { cartHandler } from '../api/cardHandler';
import parallax from './parallax';
import { sortArticles } from './sortArticles';
// import { getInlineStyle } from '../lib/utils';

gsap.registerPlugin(ScrollTrigger);

let CURSCROLL = 0;
const scrollToTop = value => {
    window.scrollTo(0, value ? value : 0);
};

if ('scrollRestoration' in history) {
    history.scrollRestoration = 'manual';
}

function initBarba() {
    function pageTransition(container) {
        const $loader = document.querySelector('.js-loader');

        const $overlay = $loader.querySelector('.js-loader-overlay');

        const $paragraphsContainer = $loader.querySelectorAll('.js-loader-paragraphs');
        const $paragraphs = $loader.querySelectorAll('.js-loader-paragraph');

        const $paragraph1 = $loader.querySelector('.js-loader-paragraph-1');
        const $paragraph1PrimaryWord = $paragraph1.querySelector('.primary-word');
        const $paragraph1SecondaryWord = $paragraph1.querySelector('.secondary-word');

        const $paragraph3 = $loader.querySelector('.js-loader-paragraph-3');
        const $paragraph3PrimaryWord = $paragraph3.querySelector('.primary-word');

        const $logo = $loader.querySelector('.js-loader-logo');
        const $infitityIcon = $logo.querySelector('.icon-logo-infinity');
        // const $infitityIcon =
        //     $logo.querySelector('.icon-logo-infinity') ||
        //     $logo.querySelector('.icon-logo-ver-2-infinity');

        const tl = gsap.timeline();

        const wideScreen = window.matchMedia('(min-width: 800px)');

        if (wideScreen.matches) {
            window.lenis.stop();

            tl.add('start')
                .to(
                    $paragraphs,
                    { duration: 2, y: 0, opacity: 1, stagger: 0.1, ease: 'expo.out' },
                    'start'
                )
                .to(
                    $infitityIcon,
                    { duration: 2.4, x: 40, stagger: 0.1, delay: 0.1, ease: Expo.easeInOut },
                    'start'
                )
                .to(
                    $paragraph3PrimaryWord,
                    { duration: 1.8, x: 0, stagger: 0.1, delay: 0.5, ease: Expo.easeInOut },
                    'start'
                )
                .to(
                    $paragraph1PrimaryWord,
                    { duration: 1.8, x: 0, stagger: 0.1, delay: 0.6, ease: Expo.easeInOut },
                    'start'
                )
                .to(
                    $paragraph1SecondaryWord,
                    { duration: 1.8, x: 0, stagger: 0.1, delay: 0.7, ease: Expo.easeInOut },
                    'start'
                )
                .fromTo(
                    $overlay,
                    { y: '100%' },
                    { duration: 1.6, y: 0, stagger: 0.1, delay: 1.2, ease: Expo.easeInOut },
                    'start'
                )
                .to(
                    $paragraphsContainer,
                    {
                        duration: 3,
                        y: '-100%',
                        opacity: 1,
                        stagger: 0.1,
                        delay: 1.2,
                        ease: 'power1.inOut',
                    },
                    'start'
                )
                .to(
                    $logo,
                    { duration: 1.8, y: -200, stagger: 0.1, delay: 1.2, ease: Expo.easeInOut },
                    'start'
                )
                .fromTo(
                    $loader,
                    { scaleY: 1 },
                    {
                        duration: 1.3,
                        scaleY: 0,
                        y: -32,
                        stagger: 0.1,
                        delay: 1.6,
                        ease: Expo.easeInOut,
                    },
                    'start'
                )
                .fromTo(
                    container,
                    { y: '400px' },
                    {
                        y: 0,
                        duration: 0.4,
                        delay: 2,
                        ease: 'power4.easeOut',
                        onComplete() {
                            gsap.set(this.targets(), { clearProps: 'all' });
                            ScrollTrigger.refresh();
                            window.lenis.start();
                            initProductScale();
                        },
                    },
                    'start'
                );
        } else {
            tl.add('start')
                .to(
                    $paragraphs,
                    { duration: 2, y: 0, opacity: 1, stagger: 0.1, ease: 'expo.out' },
                    'start'
                )
                .fromTo(
                    $infitityIcon,
                    { x: '70vw' },
                    { duration: 2.4, delay: 0.1, x: 40, ease: Expo.easeInOut },
                    'start'
                )
                .to($paragraph3PrimaryWord, { duration: 0, x: 0 }, 'start')
                .to($paragraph1PrimaryWord, { duration: 0, x: 0 }, 'start')
                .to($paragraph1SecondaryWord, { duration: 0, x: 0 }, 'start')
                .fromTo(
                    $overlay,
                    { y: '100%' },
                    { duration: 1.6, y: 0, stagger: 0.1, delay: 1.2, ease: Expo.easeInOut },
                    'start'
                )
                .to(
                    $paragraphsContainer,
                    {
                        duration: 3,
                        y: '-100%',
                        opacity: 1,
                        stagger: 0.1,
                        delay: 1.2,
                        ease: 'power1.inOut',
                    },
                    'start'
                )
                .to(
                    $logo,
                    { duration: 1.8, y: -200, stagger: 0.1, delay: 1.2, ease: Expo.easeInOut },
                    'start'
                )
                .fromTo(
                    $loader,
                    { scaleY: 1 },
                    {
                        duration: 1.6,
                        scaleY: 0,
                        y: -32,
                        stagger: 0.1,
                        delay: 1.6,
                        ease: Expo.easeInOut,
                    },
                    'start'
                )
                .fromTo(
                    container,
                    { y: '400px' },
                    {
                        y: 0,
                        duration: 0.6,
                        delay: 2,
                        ease: 'power4.easeOut',
                        onComplete() {
                            gsap.set(this.targets(), { clearProps: 'all' });
                            ScrollTrigger.refresh();
                            enableScroll();
                            initProductScale();
                        },
                    },
                    'start'
                );
        }
    }

    async function pageAnimIn(container, { onComplete, onUpdate }, namespace) {
        const myOnUpdate = onUpdate ? onUpdate : () => {};
        const t1 = gsap.timeline({
            onUpdate: () => {
                myOnUpdate();
            },
        });

        const content =
            namespace === 'page' || namespace === 'about'
                ? document.querySelector('.js-scrolling-sections-container')
                : container;

        t1.add('start')
            .fromTo(
                document.querySelector('.sail'),
                { display: 'block', scaleY: 1, y: 0 },
                { display: 'block', scaleY: 1, y: 0 },
                'start'
            )
            .fromTo(
                document.querySelector('.sail__shape'),
                { y: '100%', display: 'block', scaleY: 1 },
                {
                    y: 0,
                    duration: 1,
                    ease: Expo.easeIn,
                    onComplete() {
                        window.lenis.setScroll(0);
                    },
                },
                'start'
            )
            .to(
                content,
                {
                    y: '-200px',
                    duration: 1,
                    ease: Expo.easeIn,
                    onComplete() {
                        gsap.set(this.targets(), { clearProps: 'all' });
                        ScrollTrigger.refresh();
                        onComplete();
                    },
                },
                'start'
            );
    }

    async function pageAnimOut(container, { onComplete }) {
        const t1 = gsap.timeline();

        t1.add('start')
            .to(
                document.querySelector('.sail__shape'),
                { y: '-100%', duration: 1, ease: Expo.easeOut },
                'start'
            )
            .to(
                document.querySelector('.sail'),
                {
                    y: '-50%',
                    scaleY: 0,
                    duration: 0.7,
                    delay: 0.4,
                    ease: Expo.easeOut,
                },
                'start'
            )
            .fromTo(
                container,
                { y: '200px' },
                {
                    y: 0,
                    duration: 0.4,
                    ease: 'power4.easeOut',
                    onComplete() {
                        gsap.set(this.targets(), { clearProps: 'all' });
                        ScrollTrigger.refresh();
                        onComplete();
                        initLinkHoverAnimation();
                        initProductScale();
                        footerHover();
                    },
                },
                'start'
            );
    }

    barba.init({
        transitions: [
            {
                beforeLeave() {
                    CURSCROLL = document.documentElement.scrollTop;
                },
                leave: data => {
                    if (data.trigger === 'back') {
                        window.scrollTo(0, 0);
                        window.lenis.setScroll(0);
                    }

                    return new Promise(resolve => {
                        pageAnimIn(
                            data.current.container,
                            {
                                onComplete: () => {
                                    resolve();
                                    gsap.set(data.next.container, {
                                        clearProps: 'all',
                                    });
                                    data.current.container.remove();
                                },
                                onUpdate: () => {
                                    scrollToTop(CURSCROLL);
                                },
                            },
                            data.current.namespace
                        );
                    });
                },
                beforeEnter(data) {
                    if (data.current.namespace === 'about') {
                        data.next.container.style = 'position: absolute; top: 0;';
                        console.log('data', data.next);
                    }
                    updateSctipts();
                },
                enter: data => {
                    return new Promise(resolve => {
                        pageAnimOut(data.next.container, {
                            onComplete: () => {
                                resolve();
                                gsap.set(data.next.container, {
                                    clearProps: 'all',
                                });
                            },
                        });
                    });
                },
                once(data) {
                    const $container = data.next.container;
                    const $containerInner =
                        data.next.container.querySelector('[data-barba-namespace]');
                    const isAccountPage = $containerInner.dataset.barbaNamespace === 'account';

                    if (isAccountPage) {
                        const $loader = document.querySelector('.js-loader');
                        const t1 = gsap.timeline();

                        t1.add('start')
                            .to(
                                $loader,
                                { y: '-100%', duration: 1.2, ease: Expo.easeOut, delay: 0.1 },
                                'start'
                            )
                            .fromTo(
                                $loader,
                                { scaleY: 1 },
                                {
                                    duration: 1.6,
                                    scaleY: 0,
                                    delay: 1,
                                    ease: Expo.easeInOut,
                                },
                                'start'
                            )
                            .fromTo(
                                $container,
                                { y: '200px' },
                                {
                                    y: 0,
                                    duration: 1,
                                    ease: 'power4.easeOut',
                                    onComplete() {
                                        gsap.set(this.targets(), { clearProps: 'all' });
                                    },
                                },
                                'start'
                            );
                    } else {
                        pageTransition(data.next.container);
                        gsap.set(data.next.container, {
                            clearProps: 'all',
                        });
                    }
                },
            },
        ],
        views: [
            {
                namespace: 'product',
                beforeEnter() {
                    fetchProductGallery(() => {
                        changeModel();
                        initProduct();
                        imageZoom();
                        // parallax();
                    });

                    fetchProducts(() => {
                        initSwiperProduct();
                        copyLink();
                        poductToggles();
                        filterOut();
                    });

                    cartHandler();
                },
            },
            {
                namespace: 'blog-all',
                beforeEnter() {
                    blogAnimation();
                    sortArticles();
                },
            },
            {
                namespace: 'page',
                beforeEnter() {
                    fetchProducts();
                    stickyCursorMove();
                    initSwiperProduct();
                    overlap();
                    heroPlay();
                    filterOut();
                    cartHandler();
                },
            },
            {
                namespace: 'account',
                beforeEnter() {
                    validation();
                },
            },
            {
                namespace: 'about',
                beforeEnter() {
                    overlap();
                    copyLink();
                },
            },
            {
                namespace: 'categories',
                beforeEnter() {
                    fetchProducts();
                    stickyCursorMove();
                    filterOut();
                },
            },
            {
                namespace: 'categories-double',
                beforeEnter() {
                    fetchDoubleProducts();
                    stickyCursorMove();
                    filterOut();
                },
            },
            {
                namespace: 'article',
                beforeEnter() {
                    copyLink();
                    parallax();
                },
            },
        ],
    });
}

export default initBarba;
