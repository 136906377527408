/* eslint-disable */

import toggleCartPopup from './modules/cartPopup';

import initBarba from './modules/barba';
// import sayHello from './lib/sayHello';
// import createDat from './lib/dat';
import toggleMenu from './modules/toggle-menu-search';
import smoothScroll from './modules/smoothScroll';
import initLinkHoverAnimation from './modules/initLinkHoverAnimation';
import parallax from './modules/parallax';
import validation from './modules/validation';
import footerHover from './modules/footer';
// import setCurrentLogo from './modules/setCurrentLogo';
import disableSubmitByEnter from './modules/globalSearch';
import { cartInit } from './api/cardHandler';
import { storage } from './lib/utils';

document.addEventListener('DOMContentLoaded', event => {
    // sayHello()

    if (!storage('CLEAR_CART')) {
        storage('CART', []);
        storage('CLEAR_CART', true);
    }

    cartInit();

    // setCurrentLogo();
    initBarba();
    smoothScroll();
    validation();
    // parallax();
    toggleMenu();
    toggleCartPopup();
    initLinkHoverAnimation();
    footerHover();
    disableSubmitByEnter();

    // createDat()
});
