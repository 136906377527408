import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

function blogAnimation(isSortTrigger = false) {
    const articles = [...document.querySelectorAll('.post')];
    const wrappers = document.querySelectorAll('.post__wrapper');
    const itemsToModificate = [...wrappers].slice(1);

    const mm = gsap.matchMedia();

    const blogAnimate = () => {
        mm.add('(min-width: 1024px)', () => {
            const tls = [];
            itemsToModificate.forEach((item, i) => {
                gsap.set(item, { scaleY: 0.1 });

                const tl = ScrollTrigger.create({
                    trigger: articles[i],
                    end: '+=100%',
                    start: 'bottom bottom',
                    scrub: true,
                    animation: gsap.fromTo(item, { scaleY: 0.1 }, { scaleY: 1 }),
                });
                tls.push(tl);
                return () => {
                    tls.forEach(timeline => timeline.kill(true));
                };
            });
        });
    };

    setTimeout(() => {
        blogAnimate();
    }, 50);

    window.addEventListener('resize', () => {
        ScrollTrigger.refresh();
    });

    if (isSortTrigger) {
        ScrollTrigger.refresh();
        blogAnimate();
    }
}

export default blogAnimation;
