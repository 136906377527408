import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

const parallax = () => {
    const imageContainers = gsap.utils.toArray('.js-parallax');

    imageContainers.forEach(container => {
        const image = container.querySelector('img');
        const prevSibling = container.previousElementSibling;
        const isOverlap = prevSibling ? prevSibling.classList.contains('js-overlap') : false;
        const overlapSections = gsap.utils.toArray('.js-sticky-section');
        const mm = gsap.matchMedia();

        mm.add('(min-width: 1024px)', () => {
            const startParallax =
                isOverlap && `+=${(overlapSections.length - 2) * window.innerHeight}`;

            const tl = gsap
                .timeline({
                    scrollTrigger: {
                        trigger: container,
                        start: startParallax,
                        end: isOverlap && `+=300%`,
                        pin: false,
                        scrub: true,
                    },
                })
                .from(image, {
                    yPercent: -8,
                    ease: 'none',
                })
                .to(image, {
                    yPercent: 20,
                    ease: 'none',
                });
            return () => {
                tl.kill(true);
            };
        });
    });
};

export default parallax;
