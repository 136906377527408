/* eslint-disable */
import qs from 'qs';

import { getElementByDataType, isNull, storage } from '../lib/utils';
import { productList } from '../mock/productList';
import { CART_KEY, PRODUCT_MODEL_KEY } from '../lib/consts';
import toggleCartPopup from '../modules/cartPopup';

export const cartHandler = () => {
    const $addToCartButton = getElementByDataType('add-to-cart-button');

    window.addToCard = function addToCard(params) {
        const search = window.location.search || params;

        if (!search) return;

        let isSameProduct = false;
        const query = qs.parse(search);

        const { collection, model, price } = {
            collection: query['?collection'],
            model: isNull(storage(PRODUCT_MODEL_KEY)) ? query.model : storage(PRODUCT_MODEL_KEY),
            price: query.price,
        };

        const currentCartItems = storage(CART_KEY) || [];

        const result = currentCartItems.map(item => {
            if (item.collection === collection && item.model === model) {
                isSameProduct = true;
                return Object.assign({}, item, { quantity: Number(item.quantity) + 1 });
            }

            return item;
        });

        storage(
            CART_KEY,
            isSameProduct
                ? result
                : [...result, { collection, model, quantity: 1, price: price.split('$')[1] }]
        );

        window.openCart();
        cartInit();
    };

    $addToCartButton.addEventListener('click', addToCard);
};

export const cartInit = () => {
    const $cartInner = getElementByDataType('cart-inner');
    const $cartTotal = getElementByDataType('cart-total');
    const currentCartItems = storage(CART_KEY) || [];

    let totalPrice = 0;

    const cartList = currentCartItems.map(item => {
        const listItem = productList.find(
            i => i.collection === item.collection && i.model === item.model
        );

        totalPrice += Number(listItem.price.split('$')[1]) * item.quantity;

        return `
            <div 
                class="js-cart-item-wrapper"
                data-collection="${item.collection}"
                data-model="${item.model}"
            >
                <hr class="cart__overline js-cart-item" />
                
                <div class="cart__item js-cart-item">
                    <img src="${listItem.img}" alt="product" class="cart__img" />
                    <div class="cart__description">
                        <div class="cart__info">
                            <p>${listItem.name}</p>
                            <p>${listItem.price}</p>
                        </div>
                        <div class="cart__info">
                            <div class="cart__quantity">
                                <p>Qty</p>
                                    <div class="cart__controls">
                                        <button type="button" aria-label="decrease quantity">-</button>
                                        <input type="number" value="${item.quantity}" step="1" />
                                        <button type="button" aria-label="increase quantity">+</button>
                                    </div>
                                </div>
                            <button 
                                type="button" 
                                class="link cart__remove-btn js-remove-button" 
                            >
                                remove<span class="link__line light"></span>
                            </button>
                        </div>
                    </div> 
                </div>
            </div>
        `;
    });

    const line = '<hr class="cart__line js-line" />';

    $cartTotal.textContent = `$${totalPrice}`;
    $cartInner.innerHTML = '';
    $cartInner.insertAdjacentHTML('afterbegin', cartList.join(' '));
    $cartInner.insertAdjacentHTML('beforeend', line);

    toggleCartPopup();
    quantityHandler();
    changeStatusCart();
};

const quantityHandler = () => {
    const $increaseButtons = document.querySelectorAll('[aria-label="increase quantity"]');
    const $decreaseButtons = document.querySelectorAll('[aria-label="decrease quantity"]');

    const decrease = e => {
        const $target = e.target;
        const $currentCartItem = $target.closest('.js-cart-item-wrapper');
        const $input = $currentCartItem.querySelector('input[type="number"]');

        $input.value = $input.value - 1 <= 0 ? 1 : $input.value - 1;

        storageQuantity($currentCartItem, $input.value);
    };

    const increase = e => {
        const $target = e.target;
        const $currentCartItem = $target.closest('.js-cart-item-wrapper');
        const $input = $currentCartItem.querySelector('input[type="number"]');

        $input.value = +$input.value + 1;

        storageQuantity($currentCartItem, $input.value);
    };

    const storageQuantity = ($card, quantity) => {
        const { collection, model } = $card.dataset || {};

        const cardItems = storage(CART_KEY);

        const updatedCardItems = cardItems.map(i => {
            if (!(i.collection === collection && i.model === model)) return i;

            return Object.assign({}, i, { quantity });
        });

        storage(CART_KEY, updatedCardItems);
        calcTotal(updatedCardItems);
    };

    $increaseButtons.forEach(b => b.addEventListener('click', increase));
    $decreaseButtons.forEach(b => b.addEventListener('click', decrease));
};

export const calcTotal = cardItems => {
    const $cartTotal = getElementByDataType('cart-total');

    $cartTotal.textContent = `$${cardItems.reduce(
        (acc, val) => (acc += val.price * val.quantity),
        0
    )}`;
};

export const changeStatusCart = () => {
    const $cartInner = getElementByDataType('cart-inner');
    const $cartTotal = document.querySelector('.js-cart-total');
    const $emptyStatus = document.querySelector('.js-cart-empty');
    const $cartPopup = document.getElementById('cartPopup');

    if (document.querySelectorAll('.js-cart-item-wrapper').length) {
        $cartTotal.classList.remove('hidden');
        $emptyStatus.classList.remove('show');
        $cartInner.classList.add('bottom-line');
    } else {
        $cartTotal.classList.add('hidden');
        $emptyStatus.classList.add('show');
        $cartInner.classList.remove('bottom-line');
    }

    $cartPopup.textContent = `CART (${storage(CART_KEY) ? storage(CART_KEY).length : 0})`;
};
