/* eslint-disable */

function imageZoom() {
    const $imageWrappers = document.querySelectorAll('.js-product-zoom');

    const zoom = e => {
        const zoomer = e.currentTarget;

        const x = (e.offsetX / zoomer.offsetWidth) * 100;
        const y = (e.offsetY / zoomer.offsetHeight) * 100;

        zoomer.style.backgroundPosition = `${x}% ${y}%`;
    };

    if (window.innerWidth > 1023) {
        $imageWrappers.forEach($container => $container.addEventListener('mousemove', zoom));
    }
}

export default imageZoom;
