import gsap from 'gsap';
import { CustomEase } from 'gsap/CustomEase';

gsap.registerPlugin(CustomEase);

gsap.config({
    force3D: true,
});

const overlap = () => {
    const isAboutPage = document.querySelector('.about__section');
    window.scrollTo(0, 0);
    window.onbeforeunload = function () {
        window.scrollTo(0, 0);
    };
    const mm = gsap.matchMedia();
    let wholeHeight = 0;
    const panels = [];
    const wh = window.innerHeight;
    gsap.utils.toArray('.scrolling-section').forEach((panel, i) => {
        console.log(panel.offsetHeight, wh);
        wholeHeight += panel.offsetHeight;
        panels.push({
            panel,
            height: panel.offsetHeight,
            index: i,
            stickyImage: panel.querySelector('.js-sticky-parallax img'),
        });
    });
    mm.add('(min-width: 1024px)', () => {
        gsap.set('.js-scrolling-sections-container', {
            height: wholeHeight,
        });
        panels.forEach((panel, i) => {
            if (i !== 0) {
                gsap.set(panel.panel, {
                    y: wh,
                });
            }
        });
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: '.js-scrolling-sections-container',
                start: 'top top',
                end: `+=${wholeHeight - wh}`,
                pin: true,
                scrub: 0.5,
                pinSpacing: false,
            },
            onUpdate: p => {
                // console.log('--------',p)
                // document.dispatchEvent(new CustomEvent("scroll-move"));
            },
        });
        const ease = 'power1.out';
        // ease = 'none'
        // ease = CustomEase.create("custom", "M0,0,C0.234,0.208,0.772,1,1,1")

        if (!isAboutPage) {
            tl.fromTo(
                panels[0].panel,
                {
                    y: 0,
                },
                {
                    y: -panels[0].height + wh - 130,
                    ease,
                    duration: panels[0].height / wh,
                }
            );
        }
        let koef = 1;

        // the rest of sections
        for (let i = 1; i < panels.length; i++) {
            if (i === 2) {
                koef = 0.6;
            } // the third section is shorter
            tl.fromTo(
                panels[i].panel,
                {
                    y: wh,
                },
                {
                    y: wh - panels[i].height,
                    ease,
                    duration: panels[i].height / wh,
                }
            );
            if (panels[i].stickyImage) {
                tl.fromTo(
                    panels[i].stickyImage,
                    {
                        yPercent: -20,
                    },
                    {
                        yPercent: 0,
                        duration: 1 * koef,
                        ease,
                    },
                    '<'
                );
            }
        }
        // the promo section
        tl.fromTo(
            '.js-promo-parallax .promo__img',
            {
                yPercent: -22,
            },
            {
                yPercent: 22,
                duration: panels[3].height / wh,
            },
            '<'
        );

        if (isAboutPage) {
            tl.fromTo(
                '.js-parallax-section picture',
                {
                    yPercent: -22,
                },
                {
                    yPercent: 0,
                    duration: (0.5 * panels[3].height) / wh,
                },
                '<+=0.7'
            );
        }
    });
};

export default overlap;
