/* eslint-disable */

import Lenis from '@studio-freight/lenis';
import { disableScroll, enableScroll } from '../lib/handleScrollBody';
import {
    searchAppearance,
    searchDisappearance,
    menuAppearance,
    menuDisappearance,
} from './appearance';

const smoothScrollMenu = () => {
    const wrapper = document.querySelector('.js-menu');
    const content = wrapper.querySelector('.js-menu-content');

    const lenis = new Lenis({
        duration: 3,
        // eslint-disable-next-line no-restricted-properties
        easing: t => (t === 1 ? 1 : 1 - Math.pow(2, -10 * t)),
        direction: 'vertical',
        gestureDirection: 'vertical',
        smooth: true,
        smoothTouch: false,
        mouseMultiplier: 0.5,
        inifinite: false,
        wrapper,
        content,
    });

    window.lenisModal = lenis;

    function raf(time) {
        lenis.raf(time);
        requestAnimationFrame(raf);
    }
    requestAnimationFrame(raf);
};

const toggleMenu = () => {
    const $searchButton = document.querySelector('.js-search-button');
    const $menu = document.querySelector('.js-menu');
    const $backdrop = document.querySelector('.js-backdrop');
    const $searchBackdrop = document.querySelector('.js-search-backdrop');
    const $toggleButton = document.querySelector('.js-nav-button');
    const $productNav = document.querySelector('.js-product-nav');
    const $cart = document.querySelector('.cart');

    smoothScrollMenu();

    const menuOpen = () => {
        if ($toggleButton.classList.contains('active')) return;

        const isProductPage = !!$productNav;

        if (isProductPage) {
            $productNav.classList.add('active');
        }
        $menu.classList.add('open');
        $backdrop.classList.add('active');
        menuAppearance();
    };

    const closeMenu = () => {
        if (!$menu.classList.contains('open')) return;

        $backdrop.classList.remove('active');
        $menu.classList.remove('open');
        menuDisappearance();
    };

    const searchOpen = () => {
        if ($searchButton.classList.contains('active')) return;

        const isOpenMenu = !!$menu.classList.contains('open');
        const isProductPage = !!$productNav;

        if (isOpenMenu) {
            closeMenu();
        } else {
            $toggleButton.classList.add('active');
        }

        if (isProductPage) {
            $productNav.classList.add('active');
        }

        $searchButton.classList.add('active');
        $searchBackdrop.classList.add('active');
        searchAppearance();
    };

    window.searchClose = function searchClose() {
        if (!$searchButton.classList.contains('active')) return;

        const isProductPage = !!$productNav;

        if (isProductPage) {
            $productNav.classList.remove('active');
        }

        $toggleButton.classList.remove('active');
        $searchButton.classList.remove('active');
        $searchBackdrop.classList.remove('active');
        searchDisappearance();
    };

    const menuOpenHandler = () => {
        if ($cart.classList.contains('open')) return;
        menuOpen();
        $toggleButton.classList.add('active');
    };

    const closeMenuHandler = () => {
        closeMenu();
        $toggleButton.classList.remove('active');
        enableScroll();
    };

    $menu.addEventListener('click', closeMenuHandler);
    $toggleButton.addEventListener('click', () => {
        const isOpenMenu = !!$menu.classList.contains('open');
        const isOpenSearch = !!$searchButton.classList.contains('active');

        if (isOpenMenu && !isOpenSearch) {
            closeMenuHandler();
        } else if (!isOpenMenu && isOpenSearch) {
            searchClose();
        } else {
            menuOpenHandler();
            disableScroll();
            return;
        }

        enableScroll();
    });

    $searchButton.addEventListener('click', () => {
        searchOpen();
        disableScroll();
    });
};

export default toggleMenu;
