import SwiperCore, {
    Swiper,
    Navigation,
    FreeMode,
    Pagination,
    Thumbs,
    EffectFade,
    Grid,
    Controller,
    Manipulation,
} from 'swiper';

import isTouchDevice from '../lib/detectTouch';
import { formatPagination } from '../lib/formatPagination';

SwiperCore.use([
    Navigation,
    FreeMode,
    Pagination,
    Thumbs,
    EffectFade,
    Grid,
    Controller,
    Manipulation,
]);

function initSwiperProduct() {
    const ONE_HUNDRED_PERCENT = 100;
    const $galeryContainer = $('.gallery');
    const $gallerySlides = $galeryContainer.find('.gallery_slides');
    const $galleryPaginationCurrent = $galeryContainer.find('.swiper-pagination-current');
    const $galleryPaginationTotal = $galeryContainer.find('.swiper-pagination-total');
    const $thumbs = $('.thumbs_img');

    const productList = new Swiper('.product-list__slider', {
        slidesPerView: 4,
        slidesPerGroup: 2,
        spaceBetween: 16,
        longSwipesRatio: 0.1,
        loop: false,
        grabCursor: true,
        speed: 700,
        breakpoints: {
            0: {
                slidesPerView: 2,
                spaceBetween: 16,
            },
            701: {
                slidesPerView: 4,
                spaceBetween: 16,
            },
        },
    });

    window.swiperProductList = productList;

    const initialSlide = $galeryContainer.data('active-slide') || 0;

    const gallery = new Swiper('.gallery', {
        effect: 'fade',
        slidesPerView: 1,
        noSwiping: true,
        zoom: true,
        noSwipingClass: 'gallery_slides',
        initialSlide,
        pagination: {
            el: '.swiper-pagination',
            type: 'fraction',
            formatFractionCurrent: formatPagination,
            formatFractionTotal: formatPagination,
        },
    });

    window.swiperGalleryProduct = gallery;

    const $filter = $('[data-thumb]');

    $filter.removeClass('active');
    $(`[data-thumb='${initialSlide}']`).addClass('active');

    $filter.on('click', function (event) {
        event.preventDefault();
        const cat = $(this).data('thumb');

        $filter.removeClass('active');
        $(this).addClass('active');

        $('[data-tab]').removeClass('active');

        $('[data-tab]').each(function () {
            const workCat = $(this).data('tab');

            if (workCat === cat) {
                gallery.slideTo(cat);
                $(`[data-tab='${cat}']`).addClass('active');
            }
        });
    });

    const galleryWidth = $galeryContainer.width();
    const slidesLength = $gallerySlides.length;
    const slidesWidth = galleryWidth * slidesLength;

    const precentStep = ONE_HUNDRED_PERCENT / slidesLength;

    $galleryPaginationTotal.text(formatPagination(slidesLength));

    $gallerySlides.scroll(function (e) {
        const $target = $(e.target);

        const calcScrollInPrecent = ($target.scrollLeft() / slidesWidth) * ONE_HUNDRED_PERCENT;
        const currentSlide = Math.round(calcScrollInPrecent / precentStep) + 1;

        $galleryPaginationCurrent.text(formatPagination(currentSlide));
    });

    if (isTouchDevice()) {
        $thumbs.click(function () {
            $gallerySlides.scrollLeft(0);
            $galleryPaginationCurrent.text(formatPagination(1));
        });
    }
}

export default initSwiperProduct;
