function footerHover() {
    function activate(e) {
        e.preventDefault();
        $('.footer_list-model').removeClass('active');
        $(this).addClass('active');

        const cat = $(this).data('list');

        $('[data-id]').each(function check() {
            const workCat = $(this).data('id');
            if (workCat === cat) {
                $('.footer_content-inner').removeClass(`active`);
                $(this).addClass(`active`);
            }
        });
    }
    $('[data-list]').on('mouseover touchstart', activate);
}
export default footerHover;
