/* eslint-disable */

import gsap from 'gsap';

import { storage } from '../lib/utils';
import { CART_KEY } from '../lib/consts';
import { calcTotal, changeStatusCart } from '../api/cardHandler';
import { destroyLenisScroll, initLenisScroll } from '../lib/lenisHandler';

const toggleCartPopup = () => {
    const cartBtn = document.querySelector('#cartPopup');
    const closeBtn = document.querySelector('.cart__close');
    const cart = document.querySelector('.cart');
    const shadow = document.querySelector('.shadow');
    const menuBtn = document.querySelector('.js-nav-button');
    const line = document.querySelector('.js-line');
    const cartItems = [...document.querySelectorAll('.js-cart-item'), line];

    const tl = gsap
        .timeline({
            paused: true,
        })
        .fromTo(
            cart,
            { xPercent: 100 },
            {
                xPercent: -100,
                duration: 0.8,
                ease: 'power1.out',
            }
        );

    const cartAppearance = () => {
        tl.timeScale(1).play();

        gsap.fromTo(
            cartItems,
            { xPercent: i => i * 2 + 20 },
            {
                xPercent: 0,
                delay: 0.2,
                duration: i => i * 0.02 + 0.8,
                ease: 'power1.out',
            }
        );
    };

    const cartClose = () => {
        tl.timeScale(1.3).reverse();
    };

    window.openCart = function openCart() {
        shadow.classList.add('active');
        menuBtn.classList.add('none');
        cart.classList.add('open');
        cartAppearance();

        // STOP GENERAL PAGE SCROLL
        window.lenis.stop();

        // INIT CART SCROLL
        initLenisScroll({
            wrapperType: 'cart',
            contentType: 'cart-scroll',
            scollName: 'cartScroll',
        });
    };

    const closeCart = () => {
        shadow.classList.remove('active');
        menuBtn.classList.remove('none');
        setTimeout(() => {
            cart.classList.remove('open');
        }, 1000);
        cartClose();

        // START GENERAL PAGE SCROLL
        window.lenis.start();

        // DESTROY CART SCROLL
        destroyLenisScroll('cartScroll');
    };

    // TMP

    const removeCartItem = e => {
        const $target = e.target;
        const $currentCartItem = $target.closest('.js-cart-item-wrapper');

        const { collection, model } = $currentCartItem.dataset || {};

        const cardItems = storage(CART_KEY);
        const filteredCardItems = cardItems.filter(
            item => !(item.collection === collection && Number(item.model) === Number(model))
        );

        storage(CART_KEY, filteredCardItems);

        $currentCartItem.remove();

        calcTotal(filteredCardItems);
        changeStatusCart();
    };
    // TMP

    document
        .querySelectorAll('.js-remove-button')
        .forEach($button => $button.addEventListener('click', removeCartItem));

    cartBtn.addEventListener('click', openCart);
    closeBtn.addEventListener('click', closeCart);
};

export default toggleCartPopup;
