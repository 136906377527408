/* eslint-disable */

export const productList = [
    {
        img: 'img/categories/X1-001-1.jpg',
        imgH: 'img/categories/X1-001-2.jpg',

        imgView2: 'img/product4/X1-001-1.jpg',
        imgView2H: 'img/product4/X1-001-2.jpg',

        imgView2_1x: 'img/product/X1-001-1.jpg',
        imgView2_2x: 'img/product/X1-001-1-2x.jpg',
        imgView2_15x: 'img/product/X1-001-1-15x.jpg',

        imgView2H_1x: 'img/product/X1-001-2.jpg',
        imgView2H_2x: 'img/product/X1-001-2-2x.jpg',
        imgView2H_15x: 'img/product/X1-001-2-15x.jpg',

        collection: 'anima-x1',
        model: '001',
        name: 'ANIMA X1 001',
        price: '$245.00',
    },
    {
        img: 'img/categories/X1-002-1.jpg',
        imgH: 'img/categories/X1-002-2.jpg',

        imgView2: 'img/product4/X1-002-1.jpg',
        imgView2H: 'img/product4/X1-002-2.jpg',

        imgView2_1x: 'img/product/X1-002-1.jpg',
        imgView2_2x: 'img/product/X1-002-1-2x.jpg',
        imgView2_15x: 'img/product/X1-002-1-15x.jpg',

        imgView2H_1x: 'img/product/X1-002-2.jpg',
        imgView2H_2x: 'img/product/X1-002-2-2x.jpg',
        imgView2H_15x: 'img/product/X1-002-2-15x.jpg',

        collection: 'anima-x1',
        model: '002',
        name: 'ANIMA X1 002',
        price: '$245.00',
    },
    {
        img: 'img/categories/X1-003-1.jpg',
        imgH: 'img/categories/X1-003-2.jpg',

        imgView2: 'img/product4/X1-003-1.jpg',
        imgView2H: 'img/product4/X1-003-2.jpg',

        imgView2_1x: 'img/product/X1-003-1.jpg',
        imgView2_2x: 'img/product/X1-003-1-2x.jpg',
        imgView2_15x: 'img/product/X1-003-1-15x.jpg',

        imgView2H_1x: 'img/product/X1-003-2.jpg',
        imgView2H_2x: 'img/product/X1-003-2-2x.jpg',
        imgView2H_15x: 'img/product/X1-003-2-15x.jpg',

        collection: 'anima-x1',
        model: '003',
        name: 'ANIMA X1 003',
        price: '$245.00',
    },
    {
        img: 'img/categories/X1-004-1.jpg',
        imgH: 'img/categories/X1-004-2.jpg',

        imgView2: 'img/product4/X1-004-1.jpg',
        imgView2H: 'img/product4/X1-004-2.jpg',

        imgView2_1x: 'img/product/X1-004-1.jpg',
        imgView2_2x: 'img/product/X1-004-1-2x.jpg',
        imgView2_15x: 'img/product/X1-004-1-15x.jpg',

        imgView2H_1x: 'img/product/X1-004-2.jpg',
        imgView2H_2x: 'img/product/X1-004-2-2x.jpg',
        imgView2H_15x: 'img/product/X1-004-2-15x.jpg',

        collection: 'anima-x1',
        model: '004',
        name: 'ANIMA X1 004',
        price: '$245.00',
    },
    {
        img: 'img/categories/X2-001-1.jpg',
        imgH: 'img/categories/X2-001-2.jpg',

        imgView2: 'img/product4/X2-001-1.jpg',
        imgView2H: 'img/product4/X2-001-2.jpg',

        imgView2_1x: 'img/product/X2-001-1.jpg',
        imgView2_2x: 'img/product/X2-001-1-2x.jpg',
        imgView2_15x: 'img/product/X2-001-1-15x.jpg',

        imgView2H_1x: 'img/product/X2-001-2.jpg',
        imgView2H_2x: 'img/product/X2-001-2-2x.jpg',
        imgView2H_15x: 'img/product/X2-001-2-15x.jpg',

        collection: 'anima-x2',
        model: '001',
        name: 'ANIMA X2 001',
        price: '$245.00',
    },
    {
        img: 'img/categories/X2-002-1.jpg',
        imgH: 'img/categories/X2-002-2.jpg',

        imgView2: 'img/product4/X2-002-1.jpg',
        imgView2H: 'img/product4/X2-002-2.jpg',

        imgView2_1x: 'img/product/X2-002-1.jpg',
        imgView2_2x: 'img/product/X2-002-1-2x.jpg',
        imgView2_15x: 'img/product/X2-002-1-15x.jpg',

        imgView2H_1x: 'img/product/X2-002-2.jpg',
        imgView2H_2x: 'img/product/X2-002-2-2x.jpg',
        imgView2H_15x: 'img/product/X2-002-2-15x.jpg',

        collection: 'anima-x2',
        model: '002',
        name: 'ANIMA X2 002',
        price: '$245.00',
    },
    {
        img: 'img/categories/X2-003-1.jpg',
        imgH: 'img/categories/X2-003-2.jpg',

        imgView2: 'img/product4/X2-003-1.jpg',
        imgView2H: 'img/product4/X2-003-2.jpg',

        imgView2_1x: 'img/product/X2-003-1.jpg',
        imgView2_2x: 'img/product/X2-003-1-2x.jpg',
        imgView2_15x: 'img/product/X2-003-1-15x.jpg',

        imgView2H_1x: 'img/product/X2-003-2.jpg',
        imgView2H_2x: 'img/product/X2-003-2-2x.jpg',
        imgView2H_15x: 'img/product/X2-003-2-15x.jpg',

        collection: 'anima-x2',
        model: '003',
        name: 'ANIMA X2 003',
        price: '$245.00',
    },
    {
        img: 'img/categories/X2-004-1.jpg',
        imgH: 'img/categories/X2-004-2.jpg',

        imgView2: 'img/product4/X2-004-1.jpg',
        imgView2H: 'img/product4/X2-004-2.jpg',

        imgView2_1x: 'img/product/X2-004-1.jpg',
        imgView2_2x: 'img/product/X2-004-1-2x.jpg',
        imgView2_15x: 'img/product/X2-004-1-15x.jpg',

        imgView2H_1x: 'img/product/X2-004-2.jpg',
        imgView2H_2x: 'img/product/X2-004-2-2x.jpg',
        imgView2H_15x: 'img/product/X2-004-2-15x.jpg',

        collection: 'anima-x2',
        model: '004',
        name: 'ANIMA X2 004',
        price: '$245.00',
    },
    {
        img: 'img/categories/X3-001-1.jpg',
        imgH: 'img/categories/X3-001-2.jpg',

        imgView2: 'img/product4/X3-001-1.jpg',
        imgView2H: 'img/product4/X3-001-2.jpg',

        imgView2_1x: 'img/product/X3-001-1.jpg',
        imgView2_2x: 'img/product/X3-001-1-2x.jpg',
        imgView2_15x: 'img/product/X3-001-1-15x.jpg',

        imgView2H_1x: 'img/product/X3-001-2.jpg',
        imgView2H_2x: 'img/product/X3-001-2-2x.jpg',
        imgView2H_15x: 'img/product/X3-001-2-15x.jpg',

        collection: 'anima-x3',
        model: '001',
        name: 'ANIMA X3 001',
        price: '$245.00',
    },
    {
        img: 'img/categories/X3-002-1.jpg',
        imgH: 'img/categories/X3-002-2.jpg',

        imgView2: 'img/product4/X3-002-1.jpg',
        imgView2H: 'img/product4/X3-002-2.jpg',

        imgView2_1x: 'img/product/X3-002-1.jpg',
        imgView2_2x: 'img/product/X3-002-1-2x.jpg',
        imgView2_15x: 'img/product/X3-002-1-15x.jpg',

        imgView2H_1x: 'img/product/X3-002-2.jpg',
        imgView2H_2x: 'img/product/X3-002-2-2x.jpg',
        imgView2H_15x: 'img/product/X3-002-2-15x.jpg',

        collection: 'anima-x3',
        model: '002',
        name: 'ANIMA X3 002',
        price: '$245.00',
    },
    {
        img: 'img/categories/X3-003-1.jpg',
        imgH: 'img/categories/X3-003-2.jpg',

        imgView2: 'img/product4/X3-003-1.jpg',
        imgView2H: 'img/product4/X3-003-2.jpg',

        imgView2_1x: 'img/product/X3-003-1.jpg',
        imgView2_2x: 'img/product/X3-003-1-2x.jpg',
        imgView2_15x: 'img/product/X3-003-1-15x.jpg',

        imgView2H_1x: 'img/product/X3-003-2.jpg',
        imgView2H_2x: 'img/product/X3-003-2-2x.jpg',
        imgView2H_15x: 'img/product/X3-003-2-15x.jpg',

        collection: 'anima-x3',
        model: '003',
        name: 'ANIMA X3 003',
        price: '$245.00',
    },
    {
        img: 'img/categories/Y1-001-1.jpg',
        imgH: 'img/categories/Y1-001-2.jpg',

        imgView2: 'img/product4/Y1-001-1.jpg',
        imgView2H: 'img/product4/Y1-001-2.jpg',

        imgView2_1x: 'img/product/Y1-001-1.jpg',
        imgView2_2x: 'img/product/Y1-001-1-2x.jpg',
        imgView2_15x: 'img/product/Y1-001-1-15x.jpg',

        imgView2H_1x: 'img/product/Y1-001-2.jpg',
        imgView2H_2x: 'img/product/Y1-001-2-2x.jpg',
        imgView2H_15x: 'img/product/Y1-001-2-15x.jpg',

        collection: 'animus-y1',
        model: '001',
        name: 'ANIMUS Y1 001',
        price: '$245.00',
    },
    {
        img: 'img/categories/Y1-002-1.jpg',
        imgH: 'img/categories/Y1-002-2.jpg',

        imgView2: 'img/product4/Y1-002-1.jpg',
        imgView2H: 'img/product4/Y1-002-2.jpg',

        imgView2_1x: 'img/product/Y1-002-1.jpg',
        imgView2_2x: 'img/product/Y1-002-1-2x.jpg',
        imgView2_15x: 'img/product/Y1-002-1-15x.jpg',

        imgView2H_1x: 'img/product/Y1-002-2.jpg',
        imgView2H_2x: 'img/product/Y1-002-2-2x.jpg',
        imgView2H_15x: 'img/product/Y1-002-2-15x.jpg',

        collection: 'animus-y1',
        model: '002',
        name: 'ANIMUS Y1 002',
        price: '$245.00',
    },
    {
        img: 'img/categories/Y1-003-1.jpg',
        imgH: 'img/categories/Y1-003-2.jpg',

        imgView2: 'img/product4/Y1-003-1.jpg',
        imgView2H: 'img/product4/Y1-003-2.jpg',

        imgView2_1x: 'img/product/Y1-003-1.jpg',
        imgView2_2x: 'img/product/Y1-003-1-2x.jpg',
        imgView2_15x: 'img/product/Y1-003-1-15x.jpg',

        imgView2H_1x: 'img/product/Y1-003-2.jpg',
        imgView2H_2x: 'img/product/Y1-003-2-2x.jpg',
        imgView2H_15x: 'img/product/Y1-003-2-15x.jpg',

        collection: 'animus-y1',
        model: '003',
        name: 'ANIMUS Y1 003',
        price: '$245.00',
    },
    {
        img: 'img/categories/Y1-004-1.jpg',
        imgH: 'img/categories/Y1-004-2.jpg',

        imgView2: 'img/product4/Y1-004-1.jpg',
        imgView2H: 'img/product4/Y1-004-2.jpg',

        imgView2_1x: 'img/product/Y1-004-1.jpg',
        imgView2_2x: 'img/product/Y1-004-1-2x.jpg',
        imgView2_15x: 'img/product/Y1-004-1-15x.jpg',

        imgView2H_1x: 'img/product/Y1-004-2.jpg',
        imgView2H_2x: 'img/product/Y1-004-2-2x.jpg',
        imgView2H_15x: 'img/product/Y1-004-2-15x.jpg',

        collection: 'animus-y1',
        model: '004',
        name: 'ANIMUS Y1 004',
        price: '$245.00',
    },
    {
        img: 'img/categories/Y2-001-1.jpg',
        imgH: 'img/categories/Y2-001-2.jpg',

        imgView2: 'img/product4/Y2-001-1.jpg',
        imgView2H: 'img/product4/Y2-001-2.jpg',

        imgView2_1x: 'img/product/Y2-001-1.jpg',
        imgView2_2x: 'img/product/Y2-001-1-2x.jpg',
        imgView2_15x: 'img/product/Y2-001-1-15x.jpg',

        imgView2H_1x: 'img/product/Y2-001-2.jpg',
        imgView2H_2x: 'img/product/Y2-001-2-2x.jpg',
        imgView2H_15x: 'img/product/Y2-001-2-15x.jpg',

        collection: 'animus-y2',
        model: '001',
        name: 'ANIMUS Y2 001',
        price: '$245.00',
    },
    {
        img: 'img/categories/Y2-002-1.jpg',
        imgH: 'img/categories/Y2-002-2.jpg',

        imgView2: 'img/product4/Y2-002-1.jpg',
        imgView2H: 'img/product4/Y2-002-2.jpg',

        imgView2_1x: 'img/product/Y2-002-1.jpg',
        imgView2_2x: 'img/product/Y2-002-1-2x.jpg',
        imgView2_15x: 'img/product/Y2-002-1-15x.jpg',

        imgView2H_1x: 'img/product/Y2-002-2.jpg',
        imgView2H_2x: 'img/product/Y2-002-2-2x.jpg',
        imgView2H_15x: 'img/product/Y2-002-2-15x.jpg',

        collection: 'animus-y2',
        model: '002',
        name: 'ANIMUS Y2 002',
        price: '$245.00',
    },
    {
        img: 'img/categories/Y2-003-1.jpg',
        imgH: 'img/categories/Y2-003-2.jpg',

        imgView2: 'img/product4/Y2-003-1.jpg',
        imgView2H: 'img/product4/Y2-003-2.jpg',

        imgView2_1x: 'img/product/Y2-003-1.jpg',
        imgView2_2x: 'img/product/Y2-003-1-2x.jpg',
        imgView2_15x: 'img/product/Y2-003-1-15x.jpg',

        imgView2H_1x: 'img/product/Y2-003-2.jpg',
        imgView2H_2x: 'img/product/Y2-003-2-2x.jpg',
        imgView2H_15x: 'img/product/Y2-003-2-15x.jpg',

        collection: 'animus-y2',
        model: '003',
        name: 'ANIMUS Y2 003',
        price: '$245.00',
    },
    {
        img: 'img/categories/Y2-004-1.jpg',
        imgH: 'img/categories/Y2-004-2.jpg',

        imgView2: 'img/product4/Y2-004-1.jpg',
        imgView2H: 'img/product4/Y2-004-2.jpg',

        imgView2_1x: 'img/product/Y2-004-1.jpg',
        imgView2_2x: 'img/product/Y2-004-1-2x.jpg',
        imgView2_15x: 'img/product/Y2-004-1-15x.jpg',

        imgView2H_1x: 'img/product/Y2-004-2.jpg',
        imgView2H_2x: 'img/product/Y2-004-2-2x.jpg',
        imgView2H_15x: 'img/product/Y2-004-2-15x.jpg',

        collection: 'animus-y2',
        model: '004',
        name: 'ANIMUS Y2 004',
        price: '$245.00',
    },
    {
        img: 'img/categories/Y3-001-1.jpg',
        imgH: 'img/categories/Y3-001-2.jpg',

        imgView2: 'img/product4/Y3-001-1.jpg',
        imgView2H: 'img/product4/Y3-001-2.jpg',

        imgView2_1x: 'img/product/Y3-001-1.jpg',
        imgView2_2x: 'img/product/Y3-001-1-2x.jpg',
        imgView2_15x: 'img/product/Y3-001-1-15x.jpg',

        imgView2H_1x: 'img/product/Y3-001-2.jpg',
        imgView2H_2x: 'img/product/Y3-001-2-2x.jpg',
        imgView2H_15x: 'img/product/Y3-001-2-15x.jpg',

        collection: 'animus-y3',
        model: '001',
        name: 'ANIMUS Y3 001',
        price: '$245.00',
    },
    {
        img: 'img/categories/Y3-002-1.jpg',
        imgH: 'img/categories/Y3-002-2.jpg',

        imgView2: 'img/product4/Y3-002-1.jpg',
        imgView2H: 'img/product4/Y3-002-2.jpg',

        imgView2_1x: 'img/product/Y3-002-1.jpg',
        imgView2_2x: 'img/product/Y3-002-1-2x.jpg',
        imgView2_15x: 'img/product/Y3-002-1-15x.jpg',

        imgView2H_1x: 'img/product/Y3-002-2.jpg',
        imgView2H_2x: 'img/product/Y3-002-2-2x.jpg',
        imgView2H_15x: 'img/product/Y3-002-2-15x.jpg',

        collection: 'animus-y3',
        model: '002',
        name: 'ANIMUS Y3 002',
        price: '$245.00',
    },
    {
        img: 'img/categories/Y3-003-1.jpg',
        imgH: 'img/categories/Y3-003-2.jpg',

        imgView2: 'img/product4/Y3-003-1.jpg',
        imgView2H: 'img/product4/Y3-003-2.jpg',

        imgView2_1x: 'img/product/Y3-003-1.jpg',
        imgView2_2x: 'img/product/Y3-003-1-2x.jpg',
        imgView2_15x: 'img/product/Y3-003-1-15x.jpg',

        imgView2H_1x: 'img/product/Y3-003-2.jpg',
        imgView2H_2x: 'img/product/Y3-003-2-2x.jpg',
        imgView2H_15x: 'img/product/Y3-003-2-15x.jpg',

        collection: 'animus-y3',
        model: '003',
        name: 'ANIMUS Y3 003',
        price: '$245.00',
    },
    {
        img: 'img/categories/Y3-004-1.jpg',
        imgH: 'img/categories/Y3-004-2.jpg',

        imgView2: 'img/product4/Y3-004-1.jpg',
        imgView2H: 'img/product4/Y3-004-2.jpg',

        imgView2_1x: 'img/product/Y3-004-1.jpg',
        imgView2_2x: 'img/product/Y3-004-1-2x.jpg',
        imgView2_15x: 'img/product/Y3-004-1-15x.jpg',

        imgView2H_1x: 'img/product/Y3-004-2.jpg',
        imgView2H_2x: 'img/product/Y3-004-2-2x.jpg',
        imgView2H_15x: 'img/product/Y3-004-2-15x.jpg',

        collection: 'animus-y3',
        model: '004',
        name: 'ANIMUS Y3 004',
        price: '$245.00',
    },
];
