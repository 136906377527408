import Lenis from '@studio-freight/lenis';

const smoothScroll = () => {
    const lenis = new Lenis({
        duration: 1,
        // eslint-disable-next-line no-restricted-properties
        easing: t => Math.min(1, 1.001 - Math.pow(2, -10 * t)),
        direction: 'vertical',
        gestureDirection: 'vertical',
        smooth: true,
        smoothTouch: false,
        mouseMultiplier: 0.2,
        inifinite: false,
    });

    window.lenis = lenis;

    const thumb = document.getElementById('thumb');
    const thumbHeight = thumb.getBoundingClientRect().height;

    lenis.on('scroll', ({ progress }) => {
        thumb.style.transform = `translate3d(0,${
            progress * (window.innerHeight - thumbHeight)
        }px,0)`;
    });

    function raf(time) {
        lenis.raf(time);
        requestAnimationFrame(raf);
    }

    requestAnimationFrame(raf);
};

export default smoothScroll;
