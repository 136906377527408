import gsap from 'gsap';
import { Power2 } from 'gsap/all';

function onMouseEnterAnimation() {
    const $link = this;
    const $linkLine = $link.querySelector('.link__line');

    const tl = gsap.timeline();

    tl.add('start').fromTo(
        $linkLine,
        { x: '0' },
        { x: '100%', duration: 0.5, ease: Power2.easeInOut }
    );
}

function onMouseLeaveAnimation() {
    const $link = this;
    const $linkLine = $link.querySelector('.link__line');

    const tl = gsap.timeline();

    tl.add('start').fromTo(
        $linkLine,
        { x: '-100%' },
        { x: 0, duration: 0.5, ease: Power2.easeInOut }
    );
}

function initLinkHoverAnimation() {
    const $links = document.querySelectorAll('.link');

    if (!$links.length) return;

    $links.forEach($link => $link.addEventListener('mouseenter', onMouseEnterAnimation));
    $links.forEach($link => $link.addEventListener('mouseleave', onMouseLeaveAnimation));
}

export default initLinkHoverAnimation;
