import gsap from 'gsap';
import { storage } from '../lib/utils';
import { PRODUCT_MODEL_KEY } from '../lib/consts';

/* eslint-disable */

const changeModel = () => {
    const thumbs = [...document.querySelectorAll('.thumbs_img')];
    const modelCurrent = document.querySelector('.js-model-current');
    const modelNext = document.querySelector('.js-model-next');
    const model = document.querySelector('.js-model');
    const breadCrumbs = document.querySelector('[data-type="bread-crumbs"]');
    const modelTitle = document.querySelector('[data-type="model"]');
    const breadCrumbsModel = breadCrumbs.querySelector('[data-type="bread-crumbs-model"]');
    const breadCrumbsVariant = breadCrumbs.querySelector('[data-type="bread-crumbs-variant"]');

    const disableClick = () => {
        thumbs.forEach(thumb => (thumb.style = 'pointer-events: none'));
        thumbs[0].parentElement.style = 'cursor: pointer';
    };

    const enableClick = () => {
        thumbs.forEach(thumb => (thumb.style = ''));
        thumbs[0].parentElement.style = '';
    };

    const calcStepHeight = () => {
        return model.clientHeight - modelCurrent.clientHeight;
    };

    const animationModel = (translateY, cb = () => {}) => {
        gsap.set(model, { y: 0 });
        gsap.fromTo(
            model,
            {
                y: 0,
            },
            {
                y: translateY,
                duration: 0.8,
                onComplete: cb,
            }
        );
    };

    const toggleClass = () => {
        function onEntry(entry) {
            entry.forEach(change => {
                if (change.isIntersecting) {
                    modelCurrent.textContent = modelNext.textContent;
                }
            });

            disconnectObserver();
        }

        const options = { threshold: [0], root: document.querySelector('.single_model') };
        const observer = new IntersectionObserver(onEntry, options);
        observer.observe(modelNext);

        function disconnectObserver() {
            observer.disconnect();
            enableClick();
        }
    };

    const setBreadcrumbs = () => {
        breadCrumbsModel.textContent = modelTitle.textContent;
        breadCrumbsVariant.textContent = modelNext.textContent || modelCurrent.textContent;
    };

    const showModel = e => {
        e.preventDefault();

        let step;

        if (model) {
            step = calcStepHeight();
        }

        if (modelNext.textContent !== e.target.dataset.model) {
            disableClick();
            modelNext.textContent = e.target.dataset.model;
            animationModel(-step, toggleClass);
            setBreadcrumbs();
            storage(PRODUCT_MODEL_KEY, e.target.dataset.model);
        }
    };

    thumbs.forEach(thumb => thumb.addEventListener('click', showModel));

    // INIT BREADCRUMBS AFTER LOAD PAGE
    setBreadcrumbs();

    storage(PRODUCT_MODEL_KEY, null);
};

export default changeModel;
