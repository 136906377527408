/* eslint-disable */
import qs from 'qs';

import { productList } from '../mock/productList';
import { productGalleryList } from '../mock/productGalleryList';

export const fetchProducts = (cb = () => {}) => {
    const $container = document.querySelector('[data-type="product-list"]');

    const result = productList.map(
        product =>
            `
            <a href="/product.html?collection=${product.collection}&model=${product.model}&price=${product.price}" class="swiper-slide product__item" data-collection="${product.collection}">
                <div class="product__image js-product-list">
                    <img class="product__hover" src="${product.imgView2H}" loading="lazy" alt="">
                    <img class="product__img" src="${product.imgView2}" loading="lazy" alt="${product.name}" />
                    </div>
                        <div class="product__info">
                        <p>${product.name}</p>
                    <p class="grey">${product.price}</p>
                </div>
            </a>
`
    );

    $container.innerHTML = result.join(' ');

    cb();
};

export const fetchDoubleProducts = () => {
    const $container = document.querySelector('[data-type="double-product-list"]');

    const result = productList.map(
        product =>
            `
        <a href="/product.html?collection=${product.collection}&model=${product.model}&price=${product.price}" data-collection="${product.collection}" class="product__item">
            <div class="product__image product__image--double js-product-list">
                <img class="product__hover" src="${product.imgH}" alt="${product.name}" />
                <img class="product__img" src="${product.img}" alt="${product.name}" />
            </div>
                <div class="product__info">
                <p>${product.name}</p>
                <p class="grey">${product.price}</p>
            </div>
        </a> 
        `
    );

    $container.innerHTML = result.join(' ');
};

export const fetchProductGallery = cb => {
    const $gallery = document.querySelector('.gallery');
    const $galeryContainer = document.querySelector('[data-type="galery-container"]');
    const $galeryThumbContainer = document.querySelector('[data-type="gallery-thumb"]');
    const $galeryTitle = document.querySelector('[data-type="model"]');

    const query = qs.parse(window.location.search);

    const productGallery = productGalleryList.find(
        product => product.collection === query['?collection']
    );

    if (!productGallery) return console.log('EMPTY');

    const gallerySlides = productGallery.info.map(({ photos }, index) => {
        return `
        <div
            class="swiper-slide gallery_slides js-single"
            data-tab="${index}"
        >
            ${photos
                .map((item, idx) => {
                    const isLastItem = photos.length - 1 === idx;
                    if (isLastItem) {
                        return `
                        <div
                            class="swiper-slide__wrapper swiper-slide__zoom-box js-parallax js-product-zoom"
                            style="background-image: url(./${item['2x']})"
                        >
                                <picture>
                                    <source media="(min-width: 768px)" srcset="${item['1x']} 1x, ${item['2x']} 2x" />
                                    <source media="(max-width: 767px)" srcset="${item.mobile}" />
                                <img
                                    class="swiper-slide__img"
                                    src="${item['1x']}"
                                    alt="product"
                                    data-src="${item.zoomImg}"
                                />
                            </picture>
                        </div>
                    `;
                    } else {
                        return `
                        <div
                            class="swiper-slide__zoom-box js-product-zoom"
                            style="background-image: url(./${item['2x']})"
                        >
                            <picture>
                                <source media="(min-width: 768px)" srcset="${item['1x']} 1x, ${item['2x']} 2x" />
                                <source media="(max-width: 767px)" srcset="${item.mobile}" />
                                <img
                                    class="swiper-slide__img--base"
                                    src="${item['1x']}"
                                    alt="product"
                                    data-src="${item.zoomImg}"
                                />
                            </picture>
                        </div>
                    `;
                    }
                })
                .join(' ')}
        </div>
        `;
    });

    const galeryThumb = productGallery.info.map((item, index) => {
        if (item.model === query.model) {
            $gallery.dataset.activeSlide = index;
        }

        return `
            <img
                class="thumbs_img"
                src="${item.thumbs['1x']}"
                data-model="${item.model}"
                data-thumb="${index}"
                srcset="${item.thumbs['1x']} 1x, ${item.thumbs['2x']} 2x"
            />
    `;
    });

    $galeryTitle.textContent = productGallery.title;
    $galeryThumbContainer.innerHTML = galeryThumb.join(' ');
    $galeryContainer.innerHTML = gallerySlides.join(' ');

    cb();
};
