import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

function initProductScale() {
    const categories = document.querySelector('.categories');
    const productList = document.querySelector('.product-list');
    const elementsList = gsap.utils.toArray('.product__img');

    const fromActive = productList
        ? elementsList.findIndex(el =>
              el.closest('.swiper-slide').classList.contains('swiper-slide-active')
          )
        : null;
    const elements = categories
        ? gsap.utils.toArray('.product__img')
        : elementsList.slice(fromActive, fromActive + 4);

    const scaleAnimate = () => {
        gsap.set(elements, { scale: 1.2 });

        elements.forEach(el => {
            ScrollTrigger.create({
                trigger: el,
                start: '50% bottom',
                end: '+=10%',
                once: true,
                onEnter: () => {
                    gsap.to(el, {
                        scale: 1,
                        duration: 1.6,
                        ease: 'power2.out',
                    });
                },
            });
        });
    };

    if (elements.length > 0) {
        scaleAnimate();
    }
}

export default initProductScale;
